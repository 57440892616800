
@import '../../app/views/core/base.less';

.error {
  color: @c-error;
  display: flex;
  align-items: center;
  max-width: max-content;

  svg {
    width: 1rem;
    height: 1rem;
    fill: currentColor;
    margin-right: 0.5rem;
    flex-shrink: 0;
  }
}
