
@import '../../app/views/core/base.less';

.container {
  section {
    padding: 1rem 0;

    &:first-child {
      padding-top: 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--c-dark-gray);
    }
  }
}

.channel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
}

.link {
  display: block;
  max-width: max-content;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  .pop-text();

  .focus-outline;
}
