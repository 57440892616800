
.toolbar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.checkbox {
  column-gap: unset;
}

.select-all {
  position: absolute;
  width: 50px;
  top: 0;
  left: 0;
}

.groups-header {
  display: grid;
  grid-template-columns: 50px 50px 1fr 100px 100px;
  gap: 1rem;

  background-color: var(--c-light-gray);
  margin-bottom: 1.25rem;
  font-weight: var(--fw-bold);
  font-size: var(--fs-metadata);
  text-transform: uppercase;
  text-align: left;
  color: var(--c-medium-black);
  white-space: nowrap;

  position: relative;
}

.groups-header > span {
  padding: 12px 0;
}

.centered {
  display: flex;
  justify-content: center;
}

.group-list {
  display: grid;
  grid-gap: 1rem;
}

@media screen and (max-width: 1470px) {
  .groups-header {
    grid-template-columns: 30px 30px 1fr 100px 75px;
    gap: 0.5rem;
  }

  .select-all {
    width: 30px;
  }
}
.select-button {
  background-color: var(--c-white);
  border-radius: var(--form-border-radius);
  border: 1px solid var(--c-dark-gray);
  box-shadow: 0 1px 6px 0 var(--c-shadow);
  padding: 0.5rem 1rem;
}

.select-button-active {
  background-color: var(--c-light-gray)
}

.select-mode-actions {
  align-items: center;
  display: flex;
  margin-bottom: 1rem;
}

.selection-toolbar {
  margin-left: 1rem;
}

.text-filter {
  margin-left: auto;
  max-width: 16rem;
}
