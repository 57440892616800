
.actions {
  display: flex;
  align-items: baseline;
}

.breadcrumb {
  margin-top: -0.5rem;
  text-align: left;
}

.content {
  display: grid;
  grid-template-columns:
    [stripe-start] 0
    [side] var(--rem-details-page-cover-width)
    [main] 1fr
    [content-end] 0
    [stripe-end];
  grid-template-rows: [header] max-content [body] 1fr [footer] auto;
  grid-column-gap: 4rem;
  grid-row-gap: 2rem;
  background-color: var(--c-white);
  /* Top left+right border-radius is smaller to hide it as .stripe sits on top */
  border-radius: .625rem .625rem .5rem .5rem;
}

.stripe {
  grid-column: stripe-start / stripe-end;
  grid-row: header;
  background-color: var(--c-dark-blue);
  border-radius: .5rem .5rem 0 0;
}

.header {
  grid-column: main;
  grid-row: header;
  color: var(--c-white);
  margin: 2rem 0;
}

.label {
  background-color: var(--c-white);
  border-radius: 0.25rem;
  color: var(--c-black);
  padding: .1rem 1rem;
  line-height: normal;
  display: inline-block;
  text-transform: uppercase;
  transform: translateY(-25%);
  margin-right: 1rem;
}

.title {
  font-size: var(--fs-large-head);
  font-weight: var(--fw-bold);
  display: inline-block;
}

.subtitle {
  font-size: var(--fs-small-head);
  margin-bottom: 0.5rem;
}

.author-label {
  text-transform: uppercase;
  font-size: var(--fs-metadata);
  margin-right: 0.5rem;
}

:global(.details-page-author) {
  color: var(--c-white);
}

.pillar {
  grid-column: side;
  grid-row: header / footer;
  margin-top: 2rem;
}

.pillar > *:not(:last-child) {
  margin-bottom: 2rem;
}

.metadata {
  text-align: left;
  font-size: var(--fs-metadata);
  table-layout: fixed;
  width: 100%;
}

.metadata-row {
  padding: 1rem 0;
  display: flex;
  border-bottom: 1px solid var(--c-dark-gray);
}

.metadata-row:first-child {
  padding-top: 0;
}

.metadata-row:last-child {
  padding-bottom: 0;
  border: 0;
}

.metadata-item {
  width: 50%;
}

.metadata-label {
  color: var(--c-light-black);
  text-transform: uppercase;
}

.body {
  grid-column: main;
  display: grid;
  grid-row-gap: 2rem;
  grid-auto-rows: max-content;
}

.footer {
  grid-column: side / content-end;
  grid-row: footer;
  margin: 2rem 0;
}

/* TABLET LAYOUT */

@media screen and (max-width: 1024px /* px-vp-very-wide */) {
  .content {
    grid-template-columns: minmax(0, 1fr);
    grid-column-gap: 0;
    grid-template-rows:
      [header] max-content
      [overflow] calc(var(--rem-details-page-cover-height) / 5)
      [pillar] max-content
      [body] max-content
      [footer] max-content;
  }

  div.content > * {
    grid-column: 1;
  }

  .stripe {
    grid-row: header / span 2;
  }

  .header {
    grid-row: header;
    text-align: center;
    margin: 0;
    padding: 1rem;
  }

  .title {
    line-height: 1.25;
    margin-bottom: .5rem;
  }

  .subtitle {
    margin-bottom: 1rem;
  }

  .pillar {
    grid-column: main;
    grid-row: overflow / span 2;
    grid-row-gap: 2rem;
    margin-top: 0;
    margin-bottom: 0;
    max-width: calc(var(--rem-details-page-cover-width) * 2);
  }

  .pillar,
  .jacket,
  .metadata {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .metadata-row {
    padding: .25rem 0;
    border: 0;
  }

  .metadata-label {
    text-align: right;
    padding-right: 0.5rem;
  }

  .body {
    grid-template-columns: 100%;
    padding-top: 2rem;
    border-top: 1px solid var(--c-dark-gray);
    margin: 0 1rem;
  }

  .footer {
    grid-column: main;
    margin-top: 0;
    margin: 0 1rem 1rem;
  }
}

/* MOBILE LAYOUT */

@media screen and (max-width: 768px /* px-vp-tablet */) {
  .pillar {
    max-width: var(--rem-details-page-cover-width);
  }
}

@media screen and (max-width: 499px /* px-vp-narrow */) {
  .breadcrumb {
    margin-top: -1rem;
  }
}
