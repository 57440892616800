
.container {
  --c-set-color: var(--c-cover);
  --cover-stack-mult: 0.95;

  --rem-set-cover-width: calc(0.9 * var(--rem-cover-width));
  --rem-set-cover-height: calc(4 / 3 * var(--rem-set-cover-width));
  --rem-set-cover-sliver-width: 0.25rem;
  --c-cover-border: var(--c-darkest-gray);
}

.stack {
  position: absolute;
  background-color: var(--c-set-color);
}

.stack,
.cover {
  width: var(--rem-set-cover-width);
  height: var(--rem-set-cover-height);
  border: 1px solid var(--c-cover-border);
  box-sizing: border-box;
}

.stack0 {
  transform: scaleY(1)
      translateX(calc((3) * (var(--rem-cover-width) - var(--rem-set-cover-width)) / 3))
      translateY(0)
}

.stack1 {
  transform: scaleY(var(--cover-stack-mult))
      translateX(calc((2) * (var(--rem-cover-width) - var(--rem-set-cover-width)) / 3))
      translateY(0.1rem)
}

.stack2 {
  transform: scaleY(calc(var(--cover-stack-mult) * var(--cover-stack-mult)))
      translateX(calc((1) * (var(--rem-cover-width) - var(--rem-set-cover-width)) / 3))
      translateY(0.2rem)
}

.stack3 {
  transform: scaleY(calc(var(--cover-stack-mult) * var(--cover-stack-mult) * var(--cover-stack-mult)))
      translateX(0)
      translateY(0.3rem)
}
