
.toolbar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.select-button {
  background-color: var(--c-white);
  border-radius: var(--form-border-radius);
  border: 1px solid var(--c-dark-gray);
  box-shadow: 0 1px 6px 0 var(--c-shadow);
  padding: 0.5rem 1rem;
}

.select-button-active {
  background-color: var(--c-light-gray)
}

.select-mode-actions {
  align-items: center;
  display: flex;
  margin-bottom: 1rem;
}
