
.checkbox {
  row-gap: 0;
  column-gap: 0;
  margin-bottom: -3rem;
}

.table {
  width: 100%;
}

.table > thead {
  border-top: 1px solid var(--c-dark-gray);
  border-bottom: 1px solid var(--c-dark-gray);
  font-weight: var(--fw-bold);
  font-size: var(--fs-metadata);
  text-transform: uppercase;
  text-align: left;
  color: var(--c-medium-black);
  white-space: nowrap;
}

.table > thead th, .checkbox {
  padding: 12px 20px;
}

@media screen and (max-width: 1470px) {
  .table > thead th, .checkbox {
    padding: 12px 8px;
  }
}

.table > tbody > tr + tr > td {
  border-top: 1px solid var(--c-dark-gray);
}

.centered {
  text-align: center;
}
