

.header {
  display: flex;
  align-items: center;
  color: var(--c-darkish-black);
  text-transform: uppercase;
  font-size: var(--fs-metadata);
  margin-bottom: 0.5rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    padding-right: 0.25rem;
    fill: var(--c-darkest-gray);
  }
}

.table {
  table-layout: fixed;
  overflow: hidden;
  width: 100%;
}

.row {
  th {
    text-align: left;
    padding: 0.25rem 0;
  }

  td {
    text-align: right;
    position: relative;

    &::before {
      content: " ";
      position: absolute;
      transform: translateX(calc(-100% - 0.5rem));
      top: 50%;
      width: 200%;
      border-bottom: 1px dashed var(--c-light-black);
    }
  }
}

.label {
  position: relative;
  z-index: 2;
  background-color: var(--c-dark-black);
  padding-right: 0.5rem;
}

.loading {
  display: inline-flex;
  align-items: center;
  margin-left: auto;

  svg {
    width: 1rem;
    height: 1rem;
    padding-right: 0.25rem;
  }
}
