// Menu and surface mechanics:

.surface {
  z-index: 20;
}

.elastiguard {
  z-index: 21;
}

.surface.active {
  z-index: 25;
}

.home-title-card,
.accordion-checkbox {
  z-index: 27;
}

.home-title-card .card-circ-button,
.search-title-card-resultscontainer,
.accordion-body {
  z-index: 28;

  .show-all, .show-all-label {
    z-index: 29;
  }
}

// Surface-specific elements:

.library-home .list-preferences-button {
  z-index: 50;
}

.autocomplete-datalist {
  z-index: 55;
}

.sidebar-overlay,
.sidebar {
  z-index: 60;
}

.error-dialog {
  z-index: 65;
}


.toast > div, .prompt > div {
  z-index: 65;
}


// Bifocal iframe (non-native):

.z-bifocal-view {
  z-index: 70;
}


// Shields:

.menu-shield {
  z-index: 100;
}

.overlay {
  z-index: 100;
}

.data-book_opening,
.data-book_reopening {
  .loading-box {
    z-index: 1000;
  }
}

.onboarding-surface {
  .offline-container {
    z-index: 1000;
  }
}
