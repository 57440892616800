
.email {
  min-width: 25ch;
}

.actions {
  display: flex;
  gap: 1.25rem;
  flex-wrap: wrap;
  align-items: center;
}

.cancel {
  text-transform: uppercase;
  color: var(--c-medium-black);
  composes: linked from global;
}
.delete {
  color: var(--c-primary-red);
  display: flex;
  align-items: center;
  padding: 0.25rem;
  composes: linked from global;
  margin-left: auto;
}

.delete svg {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5em;
  fill: currentColor;
}

@media screen and (max-width: 360px /* @px-vp-very-narrow */) {
  .delete {
    margin-left: 0;
  }
}
