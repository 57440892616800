
@import '../../app/views/core/base.less';

.update-strip {
  background-color: @c-primary;
  color: @c-white;
  font-size: @fs-mini;

  padding: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 2rem;
  align-items: center;

  progress {
    grid-column: 1;
    width: 100%;
    margin: 0.6rem 0;
  }
}

.error {
  color: var(--c-white);
  background-color: var(--c-notif-error);
}

.actions {
  grid-column: 2;
  text-align: right;

  button {
    margin-left: 10px;
    vertical-align: bottom;
    padding: 5px;

    :global .icon-solid {
      fill: @c-white;
    }

    svg {
      display: block;
      stroke: @c-white;
      fill: @c-white;
      height: 1rem;
      width: 1rem;
    }

    &:disabled svg {
      cursor: default;
      stroke: @c-light-black;
      fill: @c-light-black;
    }
  }
}
