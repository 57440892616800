
.button {
  color: var(--c-dark-black);
  background-color: var(--c-light-gray);
  border-radius: var(--form-border-radius);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
}

.icon {
  fill: var(--c-medium-black);
  width: 1.5em;
  height: 1.5em;
  margin-left: 0.5em;
  flex-shrink: 0;
}

.list {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  margin-top: 0.25rem;
  max-height: 10em;
  overflow-y: auto;
  border-radius: var(--form-border-radius);
  box-shadow: 0 1px 6px 0 var(--c-shadow);
  background-color: var(--c-white);
  right: 0;
  min-width: min-content;
}

.option {
  color: var(--c-dark-black);
  padding: 1rem;
}

.selected {
  font-weight: var(--fw-bold);
  background-color: var(--c-lightest-gray);
}

.highlighted, .option:hover {
  background-color: var(--c-light-gray);
}
