
.actions {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-top: -1rem;
}

.action {
  display: flex;
  place-content: center;

  font-weight: var(--fw-bold);
  font-size: var(--fs-body);
  width: max-content;

  composes: focus-outline from global;

  min-width: calc(var(--rem-cover-width) - 1rem);
  background-color: rgba(var(--c-primary-blue-rgb), .05);
  border-radius: var(--form-border-radius);
  border: 1px solid rgba(var(--c-primary-blue-rgb), .1);
  padding: .5rem;
  color: var(--c-primary-blue);
}

.overflow {
  padding: 0 1.125rem;
  line-height: 0;
  background-color: rgba(var(--c-primary-blue-rgb), .05);
  border-radius: 50%;
}

.overflow:hover, .overflow:focus {
  border-radius: 1.5rem;
  background-color: var(--c-light-gray);
}

.overflow svg {
  height: 1.25rem;
  width: 0.625rem;
  fill: var(--c-primary-blue);
}

@media screen and (max-width: 499px /* px-vp-narrow */) {
  .actions {
    margin-top: 1rem;
  }
}
