
.text {
  white-space: pre-wrap;
  composes: word-break from global;
}

.truncated {
  --line-clamp: 1;
  composes: line-clamp from global;
}

.truncated-long {
  --line-clamp: 3;
  composes: line-clamp from global;
}

.container {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
}

.container.selectable {
  grid-template-columns: auto 1fr;
}

.panel > div {
  padding: 0.25rem 1rem; /* room for focus outlines */
}

.card {
  border: 1px solid rgba(var(--c-primary-blue-rgb), 0.1);
  border-radius: var(--form-border-radius);
  padding: 1rem;
  position: relative;

  display: grid;
  grid-template-areas:
    "color quote"
    "empty metadata"
    "empty footer";
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(3, auto);
  gap: 1rem;
}

.card.expanded {
  background-color: var(--c-lightest-gray);
}

.card.selected {
  background-color: var(--c-selected);
}

.full-card-button {
  position: absolute;
  width: 100%;
  height: 100%;

  scroll-margin: 1rem;
}

.color-strip {
  grid-area: color;

  background-color: var(--highlight-color);
  border-radius: var(--form-border-radius);
  width: 4px;
}

.metadata-list {
  grid-area: metadata;

  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(3, auto);
  gap: 0.25rem 1rem;

  color: var(--c-darkish-black);
  font-size: var(--fs-metadata);
}

.link-text {
  padding: 0.25rem;
  margin: -0.25rem;
}

.metadata-list .no-note {
  font-style: italic;
}

.footer {
  grid-area: footer;

  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 1rem;
}

.footer-metadata-list {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.last-updated {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-end;

  color: var(--c-light-black);
  font-size: var(--fs-metadata);
}

.last-updated svg {
  width: 1rem;
  height: 1rem;
  stroke: var(--c-light-black);
}

.actions {
  padding: .625rem 1.125rem;
  line-height: 0;
  background-color: rgba(var(--c-primary-blue-rgb), .05);
  border-radius: 50%;

  &:hover, &:focus {
    border-radius: 1.5rem;
    background-color: var(--c-light-gray);
  }

  svg {
    height: 1.25rem;
    width: 0.625rem;
    fill: rgba(var(--c-primary-blue-rgb), 1);
  }
}

/* Force a new stacking context to raise them above the full card buttons */
.title-action-button,
.actions {
  isolation: isolate;
}
