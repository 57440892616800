
.cover {
  --c-title: var(--c-cover);

  width: 100%;
  height: 100%;
  display: block;
  background-color: var(--c-title);

  border-radius: .125rem .3125rem .3125rem .125rem;
  box-shadow: 2px 3px 2px 1px rgba(var(--c-primary-blue-rgb), .15);
}

.bordered {
  border: 1px solid rgba(var(--c-dark-black-rgb), .1);
}
