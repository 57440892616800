
@import '../../../styles/config/ambience.less';

@icon-dimesions: 1.5rem;
@progress-circumference: 65.973px; // PROGRESS_CIRCUMFERENCE in script
@stroke: 1;

.icon {
  vertical-align: middle;
  height: @icon-dimesions;
  width: @icon-dimesions;
  stroke: @c-secondary;

  :global .fetching-circle {
    stroke-dasharray: @progress-circumference, @progress-circumference;
    stroke-dashoffset: var(--offset);
  }
}
