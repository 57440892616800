
.actions {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 1.5rem;
}

.breadcrumbs {
  margin-left: -0.5rem;
}

.info {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
}

.cover {
  --cover-width: 6rem;
  width: var(--cover-width);
  height: calc(4/3 * var(--cover-width));
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.5rem;
  padding: 0.5rem 0;
}

.title {
  font-size: var(--fs-large-head);
  font-weight: var(--fw-bold);
  line-height: 1.25;
}

.subtitle {
  line-height: 1.25;
}

.metadata {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1rem;
}

.metadata-item {
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;
  font-size: var(--fs-metadata);
}

.metadata-label {
  color: var(--c-dark-gray);
  text-transform: uppercase;
}

.metadata-value {
  color: var(--c-white);
}
