
.container {
  margin: 1rem;
}

.list-item:not(:last-child) {
  padding-bottom: 1rem;
}

.loading {
  display: block;
  width: 2rem;
  height: 2rem;
  margin: 2rem auto;
}

.error,
.empty {
  margin: 1rem;
  text-align: center;
}
