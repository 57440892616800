

.hide {
  display: none;
}

.loading {
  display: block;
  width: 2rem;
  height: 2rem;
  margin: 5rem auto;
}

.content {
  composes: list-grid from global;
  --grid-layout-gap: 1.5rem;
  --grid-column-count: 3;
  --grid-item-min-width: var(--px-card-min-width);
}

@media screen and (max-width: 499px /* px-vp-very-narrow */) {
  .content {
    --grid-layout-gap: 0;
    grid-row-gap: 1.5rem;
  }
}

