
.text-filter {
  margin: 0.75rem 0;
}

.scroll {
  max-height: 20rem;
  overflow-y: auto;
  scrollbar-width: thin;
  margin-left: -0.25rem; /* so the left side of the focus outline isn't hidden b/c of overflow: auto */
}

.item {
  padding: 0.25rem;
}

.empty {
  margin: 1rem 0 0 2.5rem;
  color: var(--c-light-black);
}

.indicator {
  composes: color-indicator from global;
  background-color: var(--highlight-color);
}
