

.empty-state {
  text-align: left;
  border-radius: 0.5rem;
}

/* Match the empty state link to the alert link */
.empty-state a {
  color: var(--c-primary-blue);
  display: inline;
  line-height: var(--lh-body);
  text-decoration: underline;
  text-underline-offset: 0.25rem;
  /* Focus padding */
  padding: 0.25rem;
  margin: -0.25rem;
}

@media screen and (max-width: 1200px /*px-vp-hide-nav*/) {
  .empty-state {
    text-align: center;
  }
}
