
.toolbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.list {
  margin-top: 2rem;
}

.list + .list {
  padding-top: 2rem;
  border-top: 1px solid var(--c-dark-gray);
}

.empty {
  margin-top: 2rem;
}

@media screen and (max-width: 360px /*@px-vp-very-narrow*/) {
  .toolbar {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
