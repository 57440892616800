
@import '../../app/views/core/base.less';

.loading {
  width: 20rem;
  height: 20rem;
  margin: auto;
  position: relative;

  svg {
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.logo {
  height: auto;
  padding: 2rem;
  border-bottom: 1px solid @c-dark-gray;
  margin: auto;
  display: block;
  box-sizing: border-box;
}

.header {
  text-align: center;
  font-size: @fs-medium-head;
  font-weight: @fw-bold;
  margin: 2rem;
}

.error {
  margin: 1rem auto;
}

.disclaimer {
  margin: 3rem auto;
  color: @c-light-black;
  text-align: center;
  max-width: 20rem;

  a {
    .focus-outline;
    .pop-text();
    white-space: pre;
  }
}

@media screen and (max-width: @px-vp-narrow) {
  .container {
    width: 100%;
  }

  .loading {
    width: 100%;
  }

  .logo {
    max-width: 100%;
    padding: 2rem 0;
  }

  .disclaimer {
    padding: 0 1rem;
  }
}
