
.option {
  align-items: center;
  display: flex;
  margin: 0.25rem 0;
}

.option svg {
  margin-right: 0.625rem;
  width: 1rem;
}

.option a, .option button {
  text-align: left;
  text-decoration: underline;
  text-underline-offset: 0.25rem;
  font-size: var(--fs-metadata);
  padding: 0.25rem;
}
