.visually-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: var(--line-clamp, 1);
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.word-break {
  /*
   * break-word is not supported everywhere,
   * and break-all is an acceptable (if worse) fallback.
   * overflow-wrap seems to have even less support,
   * but is apparently preferable to word-break.
   * https://developer.mozilla.org/en-US/docs/Web/CSS/word-break#Values
   */
  word-break: break-all;
  word-break: break-word;
  overflow-wrap: break-word;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-scrollbar {
  scrollbar-width: none; /* Firefox */
}
.no-scrollbar::-webkit-scrollbar {
  /* WebKit */
  width: 0 !important;
  height: 0 !important;
  display: none;
}

.focus-outline:focus,
.focus-outline-within:focus-within {
  outline: 3px solid transparent;
  outline-offset: 3px;
  box-shadow: var(--shadow-focus);
}

.dark .focus-outline:focus,
.dark .focus-outline-within:focus-within,
.dark.focus-outline:focus,
.dark.focus-outline-within:focus-within {
  outline: 3px solid transparent;
  outline-offset: 3px;
  box-shadow: inset 0 0 0 2px var(--c-dark-black), 0 0 0 3px var(--c-focus);
  border-radius: 8px;
}

/* TODO: Should probably be a component. */
.badge {
  display: inline-block;
  border-radius: 3px;
  color: var(--c-medium-black);
  background-color: var(--c-light-gray);
  padding: 2px 5px;
  text-align: center;
  margin-left: 10px;
  font-size: var(--fs-body);
  min-width: 10px;
}

.dark .badge,
.dark.badge {
  color: var(--c-light-gray);
  background-color: var(--c-dark-badge);
  display: inline-block;
  padding: 5px;
  font-size: var(--fs-metadata);
  font-weight: var(--fw-medium);
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
}

.series-badge {
  background-color: var(--c-series-badge);
  margin-right: 0.5rem;
  padding: 0 0.5rem;
  border-radius: 12px;
  align-self: center;
  text-transform: uppercase;
  color: var(--c-white);
  font-size: var(--fs-metadata);
}

.metadata,
.metadata-label {
  font-size: var(--fs-metadata);
  line-height: var(--lh-body);
  font-weight: var(--fw-regular);
}

.metadata-label {
  text-transform: uppercase;
}

.linked {
  text-decoration: underline;
  text-underline-offset: 0.25rem;
}

.color-indicator {
  width: 0.75rem;
  height: 0.75rem;
  display: inline-block;
  border-radius: 50%;
  margin-right: 0.25rem;
  background-color: var(--c-dark-gray);
}

.list-grid {
  /*
   * Displaying a list in a grid, that auto-fills the columns up to a given amount.
   * Define these three variables in the component style:
   * --grid-layout-gap: 1.5rem;
   * --grid-column-count: 3;
   * --grid-item-min-width: 350px;
   *
   * https://css-tricks.com/an-auto-filling-css-grid-with-max-columns/
   */

  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item-max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item-min-width), var(--grid-item-max-width)), 1fr));
  grid-gap: var(--grid-layout-gap);
}

.shadow-box {
  border: 1px solid var(--c-dark-gray);
  border-radius: var(--form-border-radius);
  box-shadow: 0 1px 6px 0 var(--c-shadow);
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
