
.container {
  background-color: var(--c-white);
  text-align: left;
  white-space: normal;
}

.info {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0.5rem;
  border-bottom: 1px solid var(--c-dark-gray);
  color: var(--c-light-black);
}

.info a {
  color: var(--c-primary-blue);
}

.results {
  margin: 1.5rem 0;
}

.error,
.no-matches {
  margin: 1rem;
  text-align: center;
}

.loading {
  display: block;
  width: 2rem;
  height: 2rem;
  margin: 2rem auto;
}
