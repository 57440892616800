:root {
  --fs-body: 16px;
  --fs-medium: 18px;
  --fs-metadata: 14px;
  --fs-large-body-head: 18px;
  --fs-medium-head: 25px;
  --fs-large-head: 35px;
  --fs-small: 12px;
  --fs-small-head: 20px;

  --lh-body: 1.5;

  --fw-bold: 700;
  --fw-medium-bold: 600;
  --fw-medium: 500;
  --fw-regular: 400;
}
