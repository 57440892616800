

.wrapper {
  display: grid;
  grid-template-columns: 1fr auto;
}

.input {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--c-light-gray);
  padding: 1rem;
  font-size: var(--fs-medium);
  border-top-left-radius: var(--form-border-radius);
  border-bottom-left-radius: var(--form-border-radius);
  border: none;
  margin: 0;
  min-width: 0;

  composes: focus-outline from global;
  composes: ellipsis from global;
}

/* Remove magnifying glass icon in iOS / iPad */
.input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.button {
  background-color: var(--c-dark-black);
  color: var(--c-white);
  padding: 1rem;
  border-top-right-radius: var(--form-border-radius);
  border-bottom-right-radius: var(--form-border-radius);
  line-height: 1;
}

.button >  svg {
  width: 1.5rem;
  height: 1.5rem;
  stroke: currentColor;
  fill: currentColor;
}

.error {
  font-size: var(--fs-mini);
  margin-top: 0.5rem;
}
