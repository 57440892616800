
@import '../../app/views/core/base.less';

.header {
  --spacing: 1rem;
  display: flex;
  flex-direction: column;
}

.update-strip-notch {
  background-color: @c-primary;
}

.mobile {
  display: grid;
  grid-template-columns: 5rem 1fr 5rem;
  align-items: center;
  padding: 0.5rem;
  background-color: @c-white;
}

.logo {
  line-height: 0;
  margin: 0 auto;
  .ellipsis;
  .focus-outline;

  img {
    height: 2.2rem;
  }
}

.icon {
  display: inline-block;
  line-height: 0;
  border-radius: 0.25rem;

  svg {
    width: 2.2rem;
    height: 2.2rem;
  }

  @media screen and (max-width: @px-vp-hide-nav) {
    justify-self: start;
  }
}

.account-icon {
  border-radius: 50%;
}

.nav-bar {
  position: fixed;
  height: 100%;
  width: @px-nav-width;
  box-sizing: border-box;
}

.strip {
  border-bottom: 1px solid var(--c-channel);
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  margin: 0 1rem;

  .search-bar,
  .menus {

    & > button,
    & > input {
      .focus-outline-dark
    }
  }

  @media screen and (max-width: @px-vp-hide-nav) {
    padding: .5rem 0;
    margin: 0 .5rem;
  }
}

.search-trigger {
  width: 100%;
}

.search-bar {
  width: 60%;

  @media screen and (max-width: @px-vp-hide-nav) {
    width: 100%;
  }
}

.channel {
  align-self: center;
  margin: 0 1rem 0 auto;

  @media screen and (max-width: @px-vp-hide-nav) {
    margin-left: 1rem;
  }
}

.menus {
  display: grid;
  gap: var(--spacing);
  grid-template-columns: auto 1fr;
  margin-right: 0.5rem;

  @media screen and (max-width: @px-vp-narrow) {
    justify-self: end;
    margin-right: 0rem;
  }
}
