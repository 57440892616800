
.status {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.25rem;
  align-items: baseline;
  padding: 0.5rem 0;
}

.icon-container {
  position: relative;
}

.icon {
  height: 1.5rem;
  width: 1.5rem;
  vertical-align: bottom;
}

.error {
  height: 1.25rem;
  width: 1.25rem;
  left: -10px;
  top: -10px;
  fill: #FF6C00;
  border-radius: 0 17px 0 0;
  position: absolute;
  background-color: white;
}

.label {
  text-decoration: underline;
  font-size: var(--fs-metadata);
}
