
.selection-toolbar {
  align-items: center;
  display: grid;
  gap: 1rem;
  grid-template-columns: auto auto;
  justify-content: start;
}

.selected-count {
  font-variant-numeric: tabular-nums;
}

.menu-button {
  align-items: center;
  background-color: var(--c-white);
  border-radius: var(--form-border-radius);
  border: 1px solid var(--c-dark-gray);
  box-shadow: 0 1px 6px 0 var(--c-shadow);
  display: grid;
  gap: 0.5rem;
  grid-template-columns: auto auto;
  padding: 0.5rem 1rem;
  position: relative;
}

.menu-button-icon {
  width: 1rem;
  height: 1rem;
}
