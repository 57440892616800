
@import '../../app/views/core/base.less';

.container {
  .welcome-panel;
}

.container > * + * {
  margin-top: 1.5rem;
}

.container > h1 {
  font-size: @fs-head;
  font-weight: @fw-bold;
  text-align: center;
}

.loading {
  width: 20rem;
  height: 20rem;
  margin: auto;
  position: relative;

  svg {
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.submit {
  display: block;
  width: 100%;
  background: @c-primary-red;
  border-radius: @px-border-radius;
  border: none;
  color: @c-white;
  font-family: @ff-sans;
  font-size: @fs-med;
  font-weight: @fw-bold;
  padding: 1rem;
  text-shadow: none;
  text-transform: uppercase;
  text-align: center;
}
