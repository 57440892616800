
  /* #region SHARED STYLES */

  /* Bar */

  .search-bar {
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-template-areas: 'search clear submit';

    background-color: var(--c-dark-blue);
    border-radius: var(--form-border-radius);
    color: var(--c-white);
  }

  .search-box-container {
    grid-area: search;

    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: 'icon box';
    align-items: center;
  }

  .search-icon {
    grid-area: icon;

    fill: var(--c-white);
    height: 1.75rem;
    width: 1.75rem;
    padding: 0.5rem 0 0.5rem 0.5rem;
  }

  .search-box {
    grid-area: box;

    background: transparent;
    border: none;
    box-sizing: border-box;
    color: var(--c-white);
    font-size: var(--fs-medium);
    font-weight: var(--fw-medium);
    line-height: 1.75rem;
    min-width: 3rem;
    overflow: hidden;
    padding: 0.25rem;
    margin: 0.25rem;
    text-align: start;
    white-space: nowrap;
  }

  .search-box::placeholder {
    color: var(--c-white);
    opacity: 0.75;
  }

  .clear-button {
    grid-area: clear;

    line-height: 0;
    padding: 0.5rem;
    margin: 0.25rem;
  }

  .clear-icon {
    fill: var(--c-white);
    height: 1rem;
    width: 1rem;
  }

  .search-button {
    grid-area: submit;

    background: var(--c-primary-red);
    border-radius: 0 var(--form-border-radius) var(--form-border-radius) 0;
    border: none;
    box-sizing: border-box;
    color: var(--c-white);
    height: 100%;
    padding: 0.5em 1em;
    text-shadow: none;
    text-transform: uppercase;
  }

  .search-box:focus,
  .clear-button:focus,
  .search-button:focus {
    outline: 3px solid transparent;
    outline-offset: 3px;
    box-shadow: inset 0 0 0 2px var(--c-dark-black), 0 0 0 3px var(--c-focus);
    border-radius: 8px;
  }

  /* Suggestions box */

  .suggestions {
    --c-heading: var(--c-black);
    --c-icon: var(--c-black);
    --c-text: var(--c-primary-blue);
    --c-separator: var(--c-darkest-gray);
  }

  .suggestions {
    padding: 1rem;
  }

  .suggestion-group + .suggestion-group {
    border-top: 1px solid var(--c-separator);
    padding-top: 1rem;
  }

  .suggestion-group-label {
    color: var(--c-heading);
    font-weight: var(--fw-bold);
  }

  .suggestion {
    display: flex;
    align-items: center;
    gap: 1rem;
    line-height: 1;
    margin: 0.75rem 0.25rem;
  }

  .suggestion > * {
    flex: none;
  }

  .suggestion-text {
    flex: 0 1 auto;
    color: var(--c-text);
    text-decoration: underline;
    text-align: start;
  }

  .suggestion svg {
    width: 1rem;
    height: 1rem;
  }

  .suggestion svg.expire-clock {
    stroke: var(--c-icon);
  }

  .suggestion svg.search,
  .suggestion svg.trashcan {
    fill: var(--c-icon);
  }

  .suggestion-text,
  .suggestion-delete-button {
    padding: 0.25rem;
    border-radius: var(--form-border-radius);
    cursor: pointer;
  }

  /* #endregion SHARED STYLES */

  /* #region SPECIFIC STYLES */

  .search-bar {
    position: relative;
    width: 60%;
  }

  .suggestions {
    position: absolute;
    top: calc(100% + 1rem);
    left: 0;

    width: 100%;
    padding: 1rem 2rem;
    background-color: var(--c-white);
    border-radius: .5rem;
    box-shadow: 0px 10px 15px 5px rgba(0,0,0,0.5);
    box-sizing: border-box;
    color: var(--c-dark-black);
  }

  .suggestion .selected {
    outline: 3px solid transparent;
    outline-offset: 3px;
    box-shadow: inset 0 0 0 3px var(--c-white), 0 0 0 3px var(--c-focus);
    border-radius: 8px;
  }

  /* #endregion SPECIFIC STYLES */
