

.page header:not(.group header) {
  padding: 4rem 2rem 2rem;
}

@media screen and (max-width: 499px /* @px-vp-narrow */) {
  .page header:not(.group header) {
    padding: 3rem 1rem 1rem;
  }
}

.loading {
  display: block;
  width: 2rem;
  height: 2rem;
  margin: 5rem auto;
}

.group:first-child {
  padding-top: 0px;
}

.group:not(:first-child) {
  border-top: 1px solid var(--c-dark-gray);
}

.button {
  margin: 50px auto 25px;
  display: table;
  padding: 10px 20px;
  color: var(--c-white);
  border-radius: var(--form-border-radius);
  background: var(--c-primary-red);
  font-weight: var(--fw-medium);
  text-transform: uppercase;
  composes: focus-outline from global;
}
