
@import '../../app/views/core/base.less';

.wrapper {
  position: relative;
  background-color: @c-light-gray;
  padding: 1rem;
  border: 1px solid @c-light-gray;
  border-radius: @px-border-radius;
  box-sizing: border-box;
  width: 100%;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  cursor: text;

  &.active {
    border-color: @c-light-gray;
    padding: 1.5rem 1rem 0.5rem;

    .label {
      top: 0.25rem;
      left: 1rem;
      font-size: @fs-mini;
      color: @c-light-black;
    }
  }

  &.focus {
    box-shadow: @shadow-focus;
    background-color: transparent;
  }

  &.error {
    border-color: @c-error;

    .label {
      color: @c-error;
    }
  }
}

.label {
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-size: @fs-med;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
  line-height: normal;
}

.input {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  font-size: @fs-med;
  width: 100%;

  /* Firefox adds a red box-shadow on :invalid inputs */
  box-shadow: none;
}

.input[type="password"] {
  width: calc(100% - 2rem);
}

.error {
  font-size: @fs-mini;
  margin-top: 0.5rem;
}

.show-password {
  width: 1rem;
  height: 1rem;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1;

  svg {
    fill: @c-light-black;
    width: 100%;
    height: 100%;
  }
}
