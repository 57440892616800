
  /* #region SHARED STYLES */

  /* Bar */

  .search-bar {
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-template-areas: 'search clear submit';

    background-color: var(--c-dark-blue);
    border-radius: var(--form-border-radius);
    color: var(--c-white);
  }

  .search-box-container {
    grid-area: search;

    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: 'icon box';
    align-items: center;
  }

  .search-icon {
    grid-area: icon;

    fill: var(--c-white);
    height: 1.75rem;
    width: 1.75rem;
    padding: 0.5rem 0 0.5rem 0.5rem;
  }

  .search-box {
    grid-area: box;

    background: transparent;
    border: none;
    box-sizing: border-box;
    color: var(--c-white);
    font-size: var(--fs-medium);
    font-weight: var(--fw-medium);
    line-height: 1.75rem;
    min-width: 3rem;
    overflow: hidden;
    padding: 0.25rem;
    margin: 0.25rem;
    text-align: start;
    white-space: nowrap;
  }

  .search-button {
    grid-area: submit;

    background: var(--c-primary-red);
    border-radius: 0 var(--form-border-radius) var(--form-border-radius) 0;
    border: none;
    box-sizing: border-box;
    color: var(--c-white);
    height: 100%;
    padding: 0.5em 1em;
    text-shadow: none;
    text-transform: uppercase;
  }

  /* #endregion SHARED STYLES */

  /* #region SPECIFIC STYLES */

  .search-bar {
    width: 100%;
  }

  .search-bar:focus {
    outline: 3px solid transparent;
    outline-offset: 3px;
    box-shadow: inset 0 0 0 2px var(--c-dark-black), 0 0 0 3px var(--c-focus);
    border-radius: 8px;
  }

  .placeholder {
    opacity: 0.75;
  }

  /* #endregion SPECIFIC STYLES */
