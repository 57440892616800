
.container {
  /* If you change this height, you also need to change the logic above */
  --em-body-max-height: calc(20em * var(--lh-body));
  position: relative;
  overflow: hidden;
  max-height: var(--em-body-max-height);
}

.container:not(.full):before {
  content: '';
  pointer-events: none;
  width: 100%;
  height: calc(0.4 * var(--em-body-max-height));
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
    to bottom,
    var(--safari-transparent),
    var(--c-lightest-gray) 80%,
    var(--c-lightest-gray) 100%);
}

.container.full {
  max-height: unset;
}

.show-all {
  z-index: 1;
  position: absolute;
  bottom: 0.5em;
  background: var(--c-lightest-gray);
  text-align: center;
  color: var(--c-primary-blue);
  composes: linked from global;
  cursor: pointer;
  margin: 0 0.25rem;
  padding: 0.25rem;
}
