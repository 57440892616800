
.actions {
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  column-gap: 0.5rem;
}

.details-link {
  appearance: none;
  composes: focus-outline from global;
  color: var(--c-primary-blue);
  font-weight: var(--fw-bold);
  font-size: var(--fs-body);
  width: max-content;

  min-width: calc(var(--rem-cover-width) - 2rem);
  background-color: rgba(var(--c-primary-blue-rgb), .05);
  border-radius: var(--form-border-radius);
  border: 1px solid rgba(var(--c-primary-blue-rgb), .1);
  padding: .5rem 1rem;
  color: var(--c-primary-blue);

  grid-column: 1;
}

.tags {
  grid-column: 3;
}

.overflow {
  padding: 0 1.125rem;
  line-height: 0;
  grid-column: 4;
  background-color: rgba(var(--c-primary-blue-rgb), .05);
  border-radius: 50%;
}

.overflow:hover, .overflow:focus {
  border-radius: 1.5rem;
  background-color: var(--c-light-gray);
}

.overflow svg {
  height: 1.25rem;
  width: 0.625rem;
  fill: var(--c-primary-blue);
}
