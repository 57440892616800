
.image {
  height: auto;
  width: auto;
  max-width: 100%;
}

.fallback {
  line-height: 1;
}
