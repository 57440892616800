
.toolbar {
  height: 40px;
}

.icon {
  display: block;
  width: 2rem;
  height: 2rem;
  margin: 2rem auto;
}
