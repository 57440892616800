

.table {
  --cell-padding: 12px 20px;
}

@media screen and (max-width: 1420px) {
  .table {
    --cell-padding: 12px 8px;
  }
}

.table {
  background-color: var(--c-white);
  border: 1px solid var(--c-dark-gray);
  text-align: start;
  width: 100%;
}

.header {
  background-color: var(--c-light-gray);
  color: var(--c-medium-black);
  font-weight: var(--fw-bold);
  font-size: var(--fs-metadata);
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap;
}

.header th {
  padding: var(--cell-padding);
}

.row td {
  padding: var(--cell-padding);
  vertical-align: top;
}

.row:not(:first-of-type) {
  border-block-start: 1px solid var(--c-dark-gray);
}

.row td[data-column="expander"],
.row td[data-column="color_id"],
.row td[data-column="syncstamp"] {
  width: auto;
  white-space: nowrap;
}

.row td[data-column="citation"],
.row td[data-column="release"] {
  min-width: 10ch;
  max-width: fit-content;
}

.row td[data-column="quote"],
.row td[data-column="note"] {
  min-width: 10ch;
}

.row td .text {
  white-space: pre-wrap;
  word-break: break-all;
  word-break: break-word;
  overflow-wrap: break-word;
}

.row:not(.expanded) .text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.row .expander-button {
  height: 2rem;
  width: 2rem;
  scroll-margin: 3rem; /* May need to adjust depending on mobile layout */
}

.row .expander {
  transition: transform 200ms ease;
}

.row.expanded .expander {
  transform: rotate(-180deg);
}

.no-note {
  font-style: italic;
  color: var(--c-medium-black);
}

