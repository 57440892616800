
.volume {
  padding: 0.25rem;
  margin: 0.25rem;
  display: block;
  color: var(--c-light-black);
  composes: focus-outline from global;
  composes: linked from global;
}

.title {
  font-size: var(--fs-medium);
  position: sticky;
  top: 0;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  background-color: #ffffffee;
  font-weight: var(--fw-bold);
  backdrop-filter: blur(5px);
}

.matches {
  border-radius: 0.5rem;
  border: 1px solid var(--c-dark-gray);
  overflow: hidden;
  counter-reset: match;
}

.matches li {
  display: flex;
  background-color: var(--c-light-gray);
  counter-increment: match;
}

.matches li:not(:last-child) {
  border-bottom: 1px solid var(--c-dark-gray);
}

.matches li::before {
  content: counter(match);
  color: var(--c-medium-black);
  background-color: var(--c-light-gray);
  padding: 1rem;
  display: block;
  font-variant-numeric: tabular-nums;
}

.match {
  background-color: var(--c-white);
  padding: 1rem;
}

.excerpt em {
  background-color: var(--c-yellow);
  font-weight: var(--fw-bold);
}

.actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 0.75rem;
}

.action,
.goto span {
  color: var(--c-primary-blue);
}

.action {
  composes: linked from global;
}

.action svg {
  display: none;
}
