
.actions {
  margin: 0.75rem 1rem;
  display: flex;
  column-gap: 1rem;
}

.submit {
  background-color: var(--c-white);
  color: var(--c-dark-black);
  border: 1px solid var(--c-white);
  height: 40px;
  line-height: 0;
}

.dismiss {
  background-color: var(--c-dark-black);
  border: 1px solid var(--c-light-black);
  height: 40px;
  line-height: 0;
}
