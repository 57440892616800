
.description i {
  font-style: italic;
}

.description a {
  color: var(--c-primary-gray);

  /* linked from utility.global.css */
  text-decoration: underline;
  text-underline-offset: 0.25rem;
}

/* focus-outline from utility.global.css */
.description a:focus {
  border-radius: 8px;
  outline: 3px solid transparent;
  outline-offset: 3px;
  box-shadow: inset 0 0 0 3px var(--c-white), 0 0 0 3px var(--c-focus);
}
