
.indicator-button {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  stroke: var(--c-secondary-gray);
}
