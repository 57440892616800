
.action {
  text-decoration: underline;
  text-underline-offset: 0.25rem;
  padding: 0.25rem 0;
}

.menu .action {
  display: block;
}

.confirmation {
  display: grid;
  grid-template-rows: auto auto;
}

.confirmation .confirmation-actions {
  display: flex;
  gap: 1rem;
}
