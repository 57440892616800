
.card {
  position: relative;
  padding: 1rem;
  border: 1px solid rgba(var(--c-primary-blue-rgb), .1);
  border-radius: var(--form-border-radius);
  color: var(--c-dark-black);
  --rem-cover-height: 11.5rem;
  --rem-cover-width: calc(3 / 4 * var(--rem-cover-height));
}

.compact {
  --rem-cover-height: 6rem;
}

.cover {
  width: var(--rem-cover-width);
  height: var(--rem-cover-height);
}

.card.borderless {
  box-shadow: none;
  border: none;
  border-bottom: 1px solid var(--c-dark-gray);
  border-radius: 0;
  padding: 1rem 0;
}

.info {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  padding: 4px;
  padding-bottom: 8px;
  position: relative;
}

.focused {
  border-radius: 0.25rem;
  composes: focus-outline-within from global;
}

.titling-container {
  display: grid;
  grid-template-columns: 1fr auto;
}

.titling {
  position: relative;
}

.link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.link:focus {
  outline: none;
  box-shadow: none;
}

.title-group {
  display: flex;
}

.title {
  font-weight: var(--fw-bold);
}

.secondary {
  color: var(--c-dark-black);
}

.title,
.secondary,
.authors,
.expansion-button {
  margin-bottom: 0.25rem;
}

.clamped {
  --line-clamp: 1;
  composes: line-clamp from global;
}

.clamped-2 {
  --line-clamp: 2;
  composes: line-clamp from global;
}

.expansion-button-container {
  align-self: end;
  line-height: 0;
  margin-right: 0.375rem;
}

.expansion-button {
  padding: 0.125rem;
}

.expansion-button svg {
  width: 1rem;
  height: 1rem;
  fill: var(--c-black);
}

.metadata {
  font-size: var(--fs-body);
  font-weight: var(--fw-regular);
  line-height: var(--lh-body);
  text-align: left;
  margin-top: 0.5rem;
  color: var(--c-darkish-black);

  width: 100%;
  max-width: max-content;
}

.metadata th {
  padding-right: 1rem;
  white-space: nowrap;
}

.metadata-label {
  color: var(--c-darkish-black);
}

.actions {
  margin-top: 1.5rem;
}

.expansion {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem 3rem 1rem 1rem;
  box-sizing: border-box;
  background: var(--c-white);
  border: 1px solid var(--c-card-border);
  border-radius: var(--form-border-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0px 1px 6px 0px var(--c-shadow);
  display: flex;
  flex-direction: column;
}

.expansion-dismiss {
  padding: 0.25rem;
  line-height: 0;
  position: absolute;
  right: 1rem;
}

.expansion-dismiss svg {
  fill: var(--c-light-black);
  width: 0.75rem;
  height: 0.75rem;
}

@media screen and (max-width: 499px /* px-vp-narrow */) {
  .card {
    --rem-cover-height: 6rem;
  }
}

@media screen and (max-width: 360px /* px-vp-very-narrow */) {
  .card {
    padding: .5rem;
  }

  .info {
    grid-template-columns: 1fr;
  }

  .metadata th {
    white-space: initial;
  }
}
