
.panel-wrapper {
  /* Matches value in setHeight function */
  --ms-accordion-transition: 200ms;
  height: 0;
  transition: height var(--ms-accordion-transition) ease;
  overflow: hidden;
}

.panel {
  padding: 1rem;
  transition: opacity var(--ms-accordion-transition);
  opacity: 1;
}
