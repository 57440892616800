.shield {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.001);
}

.shield.dark {
  background: rgba(0,0,0,0.6)
}

.standard-outline {
  outline: 3px solid transparent;
  outline-offset: 3px;
  box-shadow: inset 0 0 0 3px @c-white, 0 0 0 3px @c-focus;
  border-radius: 8px;
}

.standard-outline-dark {
  outline: 3px solid transparent;
  outline-offset: 3px;
  box-shadow: inset 0 0 0 2px @c-dark-black, 0 0 0 3px @c-focus;
  border-radius: 8px;
}


.focus-outline {
  &:focus {
    .standard-outline;
  }

  &:active {
    box-shadow: none;
  }
}

.focus-outline-dark {
  &:focus {
    .standard-outline-dark;
  }

  &:active {
    box-shadow: none;
  }
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(@px-card-min-width, 1fr));
  grid-gap: 1.5rem;

  // Prevent showing more than 3 items when the screen gets large enough to show more
  @media screen and (min-width: @px-nav-width + 4 * @px-card-min-width) {
    grid-template-columns: repeat(3, minmax(@px-card-min-width, 1fr));
  }

  @media screen and (max-width: @px-vp-narrow) {
    grid-template-columns: 1fr;
  }
}

.toolbar {
  display: flex;
  align-items: center;
  background-color: @c-lightest-gray;
  padding: 20px 2rem;

  @media screen and (max-width: @px-vp-narrow) {
    padding: 0.75rem 1.25rem;
  }
}

.welcome-panel {
  box-shadow: 0 0.625rem 1.25rem @c-dark-gray;
  border-radius: 0.25rem;
  margin: 1rem;
  padding: 3rem 4.5rem;
  width: 35rem;
  box-sizing: border-box;

  @media screen and (max-width: @px-vp-tablet) {
    margin: 0;
    border: none;
    box-shadow: none;
    padding: 1rem;
    width: 30rem;
  }

  @media screen and (max-width: @px-vp-narrow) {
    width: 100%;
  }
}

.placeholder(@color, @opacity: 1) {
  &::-webkit-input-placeholder {
    color: @color;
    opacity: @opacity;
  }
  &::-moz-placeholder {
    color: @color;
    opacity: @opacity;
  }
  &:-ms-input-placeholder {
    color: @color;
    opacity: @opacity;
  }
}

.no-scrollbar {
  scrollbar-width: none;     /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
  &::-webkit-scrollbar {     /* WebKit */
    width: 0 !important;
    height: 0!important;
    display: none;
  }
}

.pop(@fgColor: @c-fg) {
  text-decoration: underline;
  text-underline-offset: 0.25rem;
}

.pop-strict(@fgColor: @c-fg) {
  text-decoration: underline;
  text-underline-offset: 0.25rem;
}

.pop-text(@fgColor: @c-fg) {
  text-decoration: underline;
  text-underline-offset: 0.25rem;
}

.pop-text-strict(@fgColor: @c-fg) {
  text-decoration: underline;
  text-underline-offset: 0.25rem;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lineClamp(@lineCount: 1) {
  display: -webkit-box;
  -webkit-line-clamp: @lineCount;
  -webkit-box-orient: vertical;
  overflow: hidden;
}


.linearGradientFortyFive(@leftColor, @leftPercent, @rightColor, @rightPercent) {
  background: -webkit-linear-gradient(45deg, @leftColor @leftPercent, @rightColor @rightPercent);
  background: linear-gradient(45deg, @leftColor @leftPercent, @rightColor @rightPercent);
}

.show-all(@parentClass, @maxHeight, @backgroundColor) {
  &.full {
    .show-all,
    .show-all-label {
      display: none;
    }
  }

  &:not(.full){
    .show-all {
      position: absolute;
      bottom: 1em;
      opacity: 0;

      &:checked {
        display: none;

        &~.show-all-label {
          display: none;
        }
      }

      &:focus ~ .show-all-label {
        .standard-outline;
      }

      &~.show-all-label {
        position: absolute;
        bottom: 0.5em;
        background: @backgroundColor;
        text-align: center;
        .pop-text(@c-primary-blue);
        color: @c-primary-blue;
        cursor: pointer;
        margin: 0 0.25rem;
        padding: 0.25rem;
      }

      &:not(:checked) {
        &~.@{parentClass} {
          &:before {
            content: '';
            pointer-events: none;
            width: 100%;
            height: 0.4 * @maxHeight;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: linear-gradient(
              to bottom,
              @safari-transparent,
              @backgroundColor 80%,
              @backgroundColor 100%);
          }

          max-height: @maxHeight;
        }
      }
    }
  }
}

.truncation(@maxHeight) {
  max-height: @maxHeight;
  overflow: hidden;

  .word-break;
}

.word-break {
  /*
   * break-word is not supported everywhere,
   * and break-all is an acceptable (if worse) fallback.
   * overflow-wrap seems to have even less support,
   * but is apparently preferable to word-break.
   * https://developer.mozilla.org/en-US/docs/Web/CSS/word-break#Values
   */
  word-break: break-all;
  word-break: break-word;
  overflow-wrap: break-word;
}
