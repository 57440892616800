
.container {
  margin-top: 15px;
  display: grid;
  column-gap: 20px;
  grid-column-gap: 20px;
  grid-template-columns: 20px 1fr;
  grid-template-rows: 20px auto auto;
}

.sub-chapters {
  grid-row: 3;
  grid-column: 2;
}

.title {
  grid-row: 1 / span 2;
  grid-column: 2;
  max-width: max-content;
  margin-right: 10px;
}

.line {
  grid-row: 2 / end;
  grid-column: 1;
  justify-self: center;
}

.expanded > .line {
  margin-top: 1rem;
  border-left: 1px solid var(--c-darkest-gray);
}

.arrow {
  align-self: flex-start;
  grid-row: 1;
  grid-column: 1;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: var(--c-dark-gray);
  border-radius: 5px;
  padding: 1px;
  fill: currentColor;
  transform: rotate(0);
  transition: transform 150ms ease;
  margin-top: 3px;
}

.arrow svg {
  width: 18px;
  height: 18px;
}

.expanded > .arrow {
  transform: rotate(90deg);
}
