
.subscription > article {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  margin-bottom: 1rem;
}

.unsubscribe {
  min-width: calc(var(--rem-cover-width) - 1rem);
  background-color: rgba(var(--c-primary-blue-rgb), .05);
  border-radius: var(--form-border-radius);
  border: 1px solid rgba(var(--c-primary-blue-rgb), .1);
  padding: .5rem 1rem;
  color: var(--c-primary-blue);
  font-weight: var(--fw-bold);
}

@media screen and (max-width: 499px /*@px-vp-narrow*/) {
  .subscription > article {
    grid-template-columns: 1fr;
  }
}
