
.actions {
  display: grid;
  grid-template-columns: 1fr auto auto auto auto;
  grid-template-areas:
    'titleAction loan download tag overflow';
  grid-gap: 0.5rem;
  align-items: center;
}

.title-action {
  font-weight: var(--fw-bold);
  width: max-content;
  grid-area: titleAction;
}

.title-action > a, .title-action > button {
  min-width: calc(var(--rem-cover-width) - 1rem);
  background-color: rgba(var(--c-primary-blue-rgb), .05);
  border-radius: var(--form-border-radius);
  border: 1px solid rgba(var(--c-primary-blue-rgb), .1);
  padding: .5rem;
  color: var(--c-primary-blue);
}

.loan-indicator {
  display: flex;
  align-items: center;
}

.loan-indicator > button > svg {
  fill: var(--c-light-black);
  stroke: var(--c-light-black);
}

.loan {
  grid-area: loan;
}

.download {
  grid-area: download;
}

.tag {
  grid-area: tag;
}

.overflow {
  height: 2.5rem;
  width: 2.5rem;
  line-height: 0;
  background-color: rgba(var(--c-primary-blue-rgb), .05);
  border-radius: 50%;
  grid-area: overflow;
}

.overflow&:hover, .overflow&:focus {
  border-radius: 1.5rem;
  background-color: var(--c-light-gray);
}

.overflow > svg {
  height: 1.25rem;
  fill: rgba(var(--c-primary-blue-rgb), 1);
}
