
@import '../../app/views/core/base.less';

.label {
  text-decoration: inherit;
  span {
    .pop-text(@c-light-black);
  }
  text-align: left;
  &:focus{
    .focus-outline();
    display: block;
  }
}

.label-no-action {
  display: inline;
}

.truncate {
  composes: line-clamp from global;
}

.container {
  &.button {
    .action {
      background-color: @c-primary-red;
      font-weight: bold;
      text-align: center;
      padding: 1rem 0;
      border-radius: 0.25rem;
      width: 100%;
      box-shadow: 0 2px 1px 0 @c-lex-red-light;
      color: @c-white;
      text-transform: uppercase;

      &:focus {
        .focus-outline;
      }
    }
  }

  &.link {
    .action {
      &:focus {
        .focus-outline;
      }
    }
  }

  button {
    color: inherit;
    text-transform: inherit;
    box-sizing: content-box;
  }
}

.add-focus {
  a, button {
    .focus-outline;
  }
}

.action {
  text-decoration: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 0;
  fill: currentColor;
  margin-right: 0.5rem;
}
