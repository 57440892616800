
@import '../../app/views/core/base.less';

.form > * + * {
  margin-top: 1.5rem;
}

.additional {
  margin-top: 1.5rem;
  border-top: 1px solid @c-dark-gray;

  a, button {
    display: block;
    box-sizing: border-box;
    width: 100%;
    text-transform: uppercase;
    border: 1px solid @c-darkest-gray;
    border-radius: @px-border-radius;
    text-align: center;
    font-weight: @fw-bold;
    margin-top: 1.5rem;
    padding: 1rem;
    .focus-outline;
  }
}

.loading {
  width: 100%;
  height: 10rem;
  margin: auto;
  position: relative;

  svg {
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.error {
  margin: auto;
}
