
.page header:not(.card-list header):not(.empty header) {
  padding: 4rem 2rem 2rem;
}

.loading {
  display: block;
  width: 2rem;
  height: 2rem;
  margin: 5rem auto;
}

.card-list {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(425px, 1fr));
  grid-auto-rows: 1fr;
}

@media screen and (max-width: 499px /* @px-vp-narrow */) {
  .card-list {
    grid-template-columns: none;
  }

  .page header:not(.card-list header):not(.empty header) {
    padding: 3rem 1rem 1rem;
  }
}

.card-list > li:only-child {
  max-width: 650px;
}

.empty {
  margin-top: 35px;
}
