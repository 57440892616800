
  .container {
    display: grid;
    grid-template-rows: repeat(auto, 3);
    gap: 1rem;
    padding: 1rem;

    border-radius: var(--form-border-radius);
    background-color: var(--c-primary-blue);
    color: var(--c-white);
  }

  .exclamation {
    font-weight: var(--fw-bold);
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .link {
    border-radius: var(--form-border-radius);
    background-color: var(--c-white);
    color: var(--c-primary-blue);
    padding: 0.5rem 1rem;
  }

  .dismiss-button {
    text-decoration: underline;
    padding: 0.5rem 1rem;
  }
