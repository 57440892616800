
.container {
  box-shadow: 0px 1px 6px 0px var(--c-shadow);
}

.header {
  box-sizing: border-box;
  width: 100%;
}

.trigger {
  padding: 1rem;
  text-align: left;
  width: 100%;
}

.trigger-contents {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.section-wrapper {
  border: 1px solid var(--c-dark-gray);
}

.section-wrapper:first-child {
  border-top-left-radius: var(--form-border-radius);
  border-top-right-radius: var(--form-border-radius);
}

.section-wrapper:not(:last-child) {
  border-bottom: 0;
}

.section-wrapper:last-child {
  border-bottom-left-radius: var(--form-border-radius);
  border-bottom-right-radius: var(--form-border-radius);
}

.arrow {
  height: 1rem;
  transition: transform 200ms ease-in-out;
  width: 1rem;
}

.section.expanded .arrow {
  transform: rotate(90deg);
}

.section.expanded .header {
  border-bottom: 2px solid var(--c-primary-red);
  border-radius: var(--form-border-radius) var(--form-border-radius) 0 0;
}

.panel {
  background-color: var(--c-lightest-gray);
}
