
.form,
.form > * + *:not(p) {
  margin-top: 20px;
}

.form label {
  font-weight: var(--fw-bold);
}

.links {
  color: var(--c-light-black);
  font-size: var(--fs-metadata);
}

.links a {
  white-space: nowrap;
}

.form button {
  margin-left: auto;
  display: block;
}

@media screen and (max-width: 499px /* @px-vp-wide */) {
  .form button {
    width: 100%;
  }
}
