
.container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr auto;
  height: 100%;
}

.content-container {
  padding-bottom: 0;
  overflow-y: auto;
  scrollbar-gutter: stable both-edges;
  scrollbar-width: thin;
  grid-column: 1;
  grid-row: 1;
  margin-bottom: 0.25rem;
}

.header {
  font-weight: var(--fw-bold);
  width: 100%;
  font-size: var(--fs-large-body-head);
  display: flex;
  align-items: center;
}

.arrow {
  height: 1.5rem;
  transition: transform 200ms ease-in-out;
  width: 1.5rem;
  margin-left: auto;
}

.division {
  padding: 1rem 0;
  margin: 0 1rem;
  border-bottom: 2px solid var(--c-light-gray);
}

.content-container fieldset:last-child {
  border-bottom: none;
}

.apply-container {
  bottom: 0;
  position: sticky;
  border-top: 2px solid var(--c-light-gray);
  background-color: var(--c-white);
  padding: 2rem 1rem 1rem;
  grid-column: 1;
  grid-row: 2;
}

.apply-inactive {
  opacity: .6;
  cursor: default;
}

/* rotate the arrow */
.expanded {
  transform: rotate(-180deg);
}

/* transitions for list of selected filters */
.list-item {
  transition: opacity .5s ease, transform .5s ease;
  transition-delay: 100ms; /* small delay to fix transition issue on pre-applied filters */
  display: inline-flex;
}

.list-leave-active {
  position: absolute;
}

.list-enter,
.list-enter-from,
.list-leave-to {
  opacity: 0;
}

@media screen and (max-width: 360px /* px-vp-very-narrow */) {
  .apply-container {
    padding: 1rem 1rem 0;
  }
}
