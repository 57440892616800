

.content {
  width: 100%;
}

.content .list-item {
  padding: 0.5rem 0;
  margin: 0.25rem;
}

.content .list-item:not(:last-child) {
  border-bottom: 1px solid var(--c-dark-gray);
}

/* enter/leave transitions for each alert */

.list {
  overflow: hidden;
  position: relative;
}

.list-item {
  transition: opacity .5s ease, transform .5s ease;
  position: relative;
}

.list-leave-active {
  position: absolute;
  /* to keep the grid element fully sized */
  width: calc(100% - 1rem);
  box-sizing: border-box; /* to ignore padding */
}

.list-enter,
.list-enter-from,
.list-leave-to {
  opacity: 0;
}
