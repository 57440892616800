
@import '../../app/views/core/base.less';

.surface {
  position: absolute;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;

  z-index: 25;
}

.surface {
  background-color: @c-white;

  :global {
    .scroller {
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      box-sizing: border-box;
      backface-visibility: hidden;
      padding: 1rem;
      background-color: var(--c-darkest-blue);
      transition: background-color 300ms;

      display: flex;
      flex-direction: column;

      @media screen and (max-width: @px-vp-hide-nav) {
        padding: .5rem;
      }

    }

    .content {
      background-color: @c-white;
      // Top left+right border-radius is smaller to hide it where .page-header is on top of .content
      border-radius: .625rem .625rem .5rem .5rem;
      flex: 1;
    }
  }
}
