
.container {
  display: grid;
}

.tab-list {
  overflow-x: auto;

  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: 1.5rem;
  column-gap: 1.5rem;

  border-bottom: 2px solid var(--c-dark-gray);
  width: 100%;
  box-sizing: border-box;
  padding: 4px;
  padding-bottom: 0;
  margin: 0 0 2.5rem;
}

.tab {
  padding: 0.5rem;
  font-size: var(--fs-medium);
  color: var(--c-dark-black);
  transform: translateZ(0); /* Hack for Safari not clearing active styling */
}

.tab.active {
  color: var(--c-primary-red);
  border-bottom: 4px solid var(--c-primary-red);
  font-weight: var(--fw-bold);
}

.tab:focus {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.tab.active :global(.badge) {
  color: var(--c-primary-red);
  background-color: var(--c-primary-red-light);
  font-weight: var(--fw-bold);
}
