// Hide scrollbars in Windows:
//
// See "Hiding Vertical Scrollbars with Pure CSS": http://bit.ly/2fWa1vB

// .scroller::-webkit-scrollbar {
//   display: none;
// }

.native-scrollable {
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

// Disable underlayer scrolling while a modal (eg popover) is up:
&.data-scrollfocus_popover,
&.data-scrollfocus-picker,
&.data-scrollfocus_update {
  .native-scrollable {
    // overflow-y: hidden !important;
    -webkit-overflow-scrolling: initial !important;
  }
}

// But allow scrolling inside a popover:
&.data-scrollfocus_popover {
  .popover-scroller {
    // overflow-y: auto !important;
    -webkit-overflow-scrolling: touch !important;
  }
}
