
@import '../../app/views/core/base.less';

.container {
  background-color: var(--c-darkest-blue);
  border-bottom: 2px solid @c-primary-red;
  position: sticky;
  top: -1rem;
  z-index: 100;
}

.bar {
  padding: 1rem 1.75rem;
  display: flex;
  margin-top: -1rem;

  .header-link:last-child {
    margin-left: auto;
  }
}

.header-link {
  color: @c-white;
  .pop-text();
  .focus-outline-dark;
}

@media screen and (max-width: @px-vp-narrow) {
  .bar {
    padding: 1rem 2rem;
  }
}
