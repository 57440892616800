
@import '../../app/views/core/base.less';

@px-vp-hide-survey: 850px;

.container {
  box-shadow: 0 1px 6px @c-shadow;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  padding: 1rem;
  transition: transform 300ms ease;
  background-color: @c-white;
}

.dismiss {
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.25rem;
  display: block;
  margin-left: auto;
  line-height: 0;

  svg {
    width: 100%;
    height: 100%;
  }
}

.image {
  height: 3.5rem;
  margin: auto;
  display: block;
}

.header {
  font-weight: @fw-bold;
  font-size: @fs-small-head;
  text-align: center;
}

.description {
  text-align: center;
}

.link {
  text-align: center;
  width: 100%;
  background-color: @c-primary-red;
  color: @c-white;
  text-transform: uppercase;
  border-radius: 0.25rem;
  display: block;
  padding: 0.5rem 0;
  font-weight: @fw-medium;
}

.header,
.description,
.link {
  margin: 1rem 0;
}

@media screen and (max-height: @px-vp-hide-survey) {
  .image {
    display: none;
  }

  .header,
  .description,
  .link {
    margin: 0.5rem 0;
  }
}
