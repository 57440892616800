:root {
  --c-primary-red: #E8171F;
  --c-primary-red-light: #FCE8EA;
  --c-cover: var(--c-light-black);
  --c-primary-gray: #424242;
  --c-secondary-gray: #2D2D2D;
  --gradient-gray: linear-gradient(45deg, var(--c-primary-gray) 0, var(--c-secondary-gray) 75%);
  --safari-transparent : rgba(255,255,255,0.001) 0%;
  --c-black: #111;
  --c-dark-black-rgb: 33, 33, 33;
  --c-dark-black: rgba(var(--c-dark-black-rgb), 1);
  --c-darkish-black: #616161;
  --c-medium-black: #444444;
  --c-light-black: #777777;
  --c-darkest-gray: #BBBBBB;
  --c-dark-gray: #DDDDDD;
  --c-light-gray: #F0F0F0;
  --c-lightest-gray: #F8F8F8;
  --c-white: rgba(var(--c-white-rgb), 1);
  --c-white-rgb: 255, 255, 255;
  --c-shadow: #2222221a;
  --c-focus: #FFDF33;
  --c-notif-success: #46A521;
  --c-notif-error: #F22222;
  --c-notif-info: var(--c-dark-gray);
  --c-notif-warning: hsl(29, 100%, 43%);
  --c-selected: #FFE98F6E;
  --c-card-border: #EEEEEE;
  --c-dark-badge: #555555;
  --c-error-orange: #FF6C00;
  --c-primary-blue-rgb: 41, 98, 255;
  --c-primary-blue: rgba(var(--c-primary-blue-rgb), 1);
  --c-darkest-blue: #00172E;
  --c-dark-blue: #132A43;
  --c-series-badge: #222222;
  --c-yellow: #FFEE77;
  --shadow-focus: inset 0 0 0 2px var(--c-white), 0 0 0 3px var(--c-focus);

  /* Highlight colors */
  --highlight-color-RL: #ffccce;
  --highlight-color-RS: #ff7979;
  --highlight-color-OL: #fbd7a9;
  --highlight-color-OS: #ffae5b;
  --highlight-color-YL: #f2f098;
  --highlight-color-YS: #fffa67;
  --highlight-color-GL: #b9f9ca;
  --highlight-color-GS: #5ef987;
  --highlight-color-TL: #b4fffb;
  --highlight-color-TS: #54f9f1;
  --highlight-color-BL: #a2e3fd;
  --highlight-color-BS: #51cfff;
  --highlight-color-VL: #eecffb;
  --highlight-color-VS: #e29dff;
}
