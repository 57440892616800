

  .text-filter {
    margin-left: auto;
    max-width: 16rem;
  }

  .edit-tag-button {
    margin-left: auto;
  }

  .list-container {
  flex: 1;
  background-color: var(--c-white);
  border-radius: .625rem .625rem .5rem .5rem;
  }

  .card-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--px-card-min-width), 1fr));
    grid-gap: 1.5rem;
    flex: 1;
  }

  .count {
    width: fit-content;
    margin-left: auto;
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 499px /* px-vp-narrow */) {
    .toolbar {
      padding: 0.75rem 1.25rem;
    }

    .card-list {
      grid-template-columns: 1fr;
    }
  }
