
@import '../../app/views/core/base.less';

.initial-view {
  background-color: var(--c-white);
  margin: 0 -1rem;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--c-white);
  min-height: 100vh;
}
