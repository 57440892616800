
@import '../../app/views/core/base.less';

.container {
  max-width: 25rem;

  @media screen and (max-width: @px-vp-max) {
    max-width: auto;
  }
}

.header {
  font-weight: @fw-bold;
  font-size: @fs-large-head;
  font-family: @ff-head;

  margin-bottom: 1.5rem;
}

.list {
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid @c-darkest-gray;

  li + li {
    margin-top: 0.5rem;
  }
}

.link {
  color: @c-primary-blue;
  .pop-strict(@c-primary-blue-light);
  .focus-outline;
  max-width: max-content;
  .lineClamp;
}

.empty-state {
  text-align: left;
}
