
.container {
  display: flex;
  box-shadow: 0 1px 6px 0 var(--c-shadow);
}

.label {
  background-color: var(--c-white);
  border-radius: var(--form-border-radius) 0 0 var(--form-border-radius);
  color: var(--c-medium-black);
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
}

.label, .dropdown {
  border: 1px solid var(--c-dark-gray);
}

.label {
  border-right: 0;
}

.dropdown {
  flex-grow: 1;
  border-radius: 0 var(--form-border-radius) var(--form-border-radius) 0;
}

.label:global(.visually-hidden) + .dropdown {
  border-radius: var(--form-border-radius);
}

.dropdown button {
  padding: 0.5rem 0.75rem;
  background-color: var(--c-light-gray);
}
