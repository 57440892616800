.u-hide {
  display: none !important;
}

// Used for counts next to labels/elements.
// Similar to how twitter bootstrap defines 'badges'
.badge {
  color: @c-light-black;
  background-color: @c-light-gray;
  display: inline-block;
  padding: 5px;
  font-size: @fs-metadata;
  font-weight: @fw-medium;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
}