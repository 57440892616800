
.row td {
  padding: 16px 20px;
  vertical-align: top;
}

@media screen and (max-width: 1470px) {
  .row td {
    padding: 16px 8px;
  }
}

.text {
  --line-clamp: 3;
  white-space: pre-wrap;
  composes: line-clamp from global;
  composes: word-break from global;
}

/* link-text & citation-panel classes needed to see full focus ring around TitleActionButton */
.link-text {
  padding: 0.25rem;
  margin: -0.25rem;
}

.citation-panel {
  margin: -1rem;
}

.citation-panel > div {
  padding: 1rem;
}

.checkbox {
  row-gap: 0;
  column-gap: 0;
}

.row.expanded .text {
  overflow: visible;
  display: block;
}

.expander {
  width: 3ch;
  height: 3ch;
  scroll-margin: 1.5rem;
}

.expander svg {
  transition: transform 150ms ease-in-out;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  transform: translateZ(1px) rotateX(0);
}

.row.expanded .expander svg {
  transform: translateZ(1px) rotateX(180deg);
}

.row.selected {
  background-color: var(--c-selected);
}

.no-note {
  font-style: italic;
  color: var(--c-medium-black);
}


.row [data-column="selected"],
.row [data-column="expander"],
.row [data-column="color"],
.row [data-column="actions"],
.row [data-column="lastUpdated"] {
  width: auto;
  white-space: nowrap;
}

.row [data-column="citation"],
.row [data-column="release"] {
  min-width: 10ch;
  max-width: fit-content;
}

.row [data-column="highlight"],
.row [data-column="note"] {
  min-width: 10ch;
}

.row [data-column="actions"] {
  text-align: center;
}

.actions {
  height: 100%;
  width: 18px;
  fill: var(--c-light-black);
  line-height: 0;
  padding: 2px 6px;
}
