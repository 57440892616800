
.header {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1rem;
  row-gap: 0.25rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--c-dark-gray);
  margin-bottom: 1rem;
}

.cover {
  grid-row: 1 / span 2;
  grid-column: 1;
  width: 2.5rem;
  height: 3.25rem;
  border: 1px solid var(--c-darkest-gray);
}

.title {
  grid-row: 1;
  grid-column: 2;
  font-size: var(--fs-medium);
  font-weight: var(--fw-bold);
}

.subtitle {
  grid-row: 2;
  grid-column: 2;
  color: var(--c-light-black);
  font-size: var(--fs-body);
}
