
@import '../../app/views/core/base.less';

.empty-state {
  display: grid;
  justify-content: center;
  grid-template-columns: 32rem;
  grid-auto-rows: max-content;

  font-size: @fs-med;
  text-align: center;
  background-color: var(--c-white);
  padding: 5rem 2rem 2rem;
  border-radius: 0 0 .5rem .5rem;

  flex: 1;

  @media screen and (max-width: @px-vp-tablet) {
    grid-template-columns: inherit;
  }

}

.header {
  font-size: @fs-loud;
  color: @c-dark-black;
  font-weight: @fw-bold;
  line-height: @lh-head;
  padding-bottom: 1rem;
}

.content {
  color: @c-medium-black;
  line-height: @lh-body;
  padding-bottom: 1.6rem;
}

.link {
  .pop(@c-primary-blue);
  color: @c-primary-blue;
  display: inline;
  line-height: @lh-body;
}

// Overrides

.compact {
  margin: 0;
  padding: .5rem;
  max-width: 38rem;
  border-radius: 0;
  display: inline-block;
}

.dark-mode {

  background-color: inherit;

  .header {
    color: @c-white;
  }
  .content {
    color: @c-dark-gray;
  }
  .link {
    color: @c-darkest-gray;
    .pop(@c-darkest-gray);
  }
}

// Logo Classes

.logo-image-wide {
  display: inline;
  margin: 0 auto;
  width: 400px;
  height: 100px;
}

.logo-image-short {
  display: inline;
  margin: 0 auto;
  width: 325px;
  height: 100px;
}

.logo-image-compact {
  margin: 0 initial;
  width: 190px;
  height: 50px;
}
