

.content {
  composes: list-grid from global;
  --grid-layout-gap: 1.5rem;
  --grid-column-count: 4;
  --grid-item-min-width: 250px;
}

.item {
  display: grid;
  grid-template-columns: 75px auto 1fr;
  grid-gap: 5px;
}

.title-count {
  color: var(--c-light-black);
}

.name {
  composes: linked from global;
  composes: focus-outline from global;
  color: var(--c-primary-blue);
}
