
.dropdown-button {
  display: flex;
  align-content: center;
  padding: 0.25rem 0.5rem;
  border: 2px solid var(--c-channel);
  color: var(c--dark-black);
  font-weight: bold;
  font-size: 1rem;
  border-radius: var(--form-border-radius);
}

.dropdown-list {
  position: absolute;
  padding: 0.5rem;
  right: 0;
  transform: translateY(0.25rem);
  display: grid;
  grid-template-columns: max-content;
  grid-auto-flow: row;
  grid-row-gap: 0.5rem;
  border-radius: var(--form-border-radius);
  background-color: var(--c-light-gray);
  color: var(--c-dark-black);
  box-shadow: 0px 3px 6px rgba(34, 34, 34, 0.15);
}

.dropdown-option {
  padding: 0.5rem;
  min-width: max-content;
  border-left: 4px solid transparent;
}

.dropdown-option:last-child {
  margin-bottom: 0;
}

.dropdown-highlighted,
.dropdown-option:hover {
  color: var(--c-dark-black);
  background-color: var(--c-dark-gray);
}

.dropdown-selected {
  border-color: var(--c-dark-black);
}
