
.widget {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 0.25rem;
  border: 1px solid var(--c-darkest-gray);
  padding: 0.25rem;
  border-radius: var(--form-border-radius);
}

.input-field {
  border: none;
  padding: 0 0.25rem;
  composes: focus-outline from global;
  composes: ellipsis from global;
}

.dropdown {
  width: calc(var(--width-size) - 0.5rem);
  display: grid;
  grid-gap: 0.25rem;
  margin: 0.5rem 0.25rem;
}

.dropdown-list {
  width: inherit;
}

.dropdown-hint {
  padding: 0 0.25rem;
  max-width: 100%;
  composes: ellipsis from global;
}

.apply-button {
  padding: 0 0.25rem;
  border: 1px solid var(--c-dark-gray);
  border-radius: var(--form-border-radius);
}

.applied-list {
  padding: 0 0.25rem;
  margin: 0.5rem -0.25rem 0;
  /* scrollbar styling for Firefox */
  scrollbar-color: var(--c-light-black) var(--c-light-gray);
}

.scrollable {
  max-height: 100px;
  overflow-y: auto;
}

/* scrollbar styling for Chrome, Safari */
.applied-list::-webkit-scrollbar {
  width: .3rem;
}

.applied-list::-webkit-scrollbar-track {
  background: var(--c-light-gray);
  border-left: .1rem solid white;
  border-right: .1rem solid white;
}

.applied-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--c-light-black);
}

.applied-list::-webkit-scrollbar-thumb &:hover {
    background: var(--c-darkest-gray);
  }

.applied-list-item {
  transition: opacity .5s ease, transform .5s ease;
  display: inline-flex;
  max-width: 100%;
}

.list-leave-active {
  position: absolute;
}

.list-enter,
.list-enter-from,
.list-leave-to {
  opacity: 0;
}

.applied {
  background-color: var(--c-white);
  border-radius: var(--form-border-radius);
  border: 1px solid var(--c-dark-black);
  padding: 0 0.25rem;
  font-size: var(--fs-metadata);
  margin: 0.25rem 0.5rem 0.25rem 0;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  max-width: calc(100% - 0.5rem);
}

.applied-text {
  composes: ellipsis from global;
}


.dismiss {
  height: 0.6rem;
  width: 0.6rem;
  padding-left: 0.5rem;
  fill: var(--c-dark-black);
}
