
.actions {
  display: grid;
  grid-template-rows: auto auto;
  gap: 1rem;

  padding: 0.5rem 0rem;
  border-bottom: 1px solid var(--c-dark-gray);
}

.links {
  display: grid;
  grid-template-columns: auto auto;

  align-items: center;
  justify-content: space-between;
}

.link {
  color: var(--c-primary-blue);
  display: inline;
  line-height: var(--lh-body);
  text-decoration: underline;
  text-underline-offset: 0.25rem;
  /* Focus padding */
  padding: 0.25rem 0.5rem;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.error-dialog h3 {
  font-weight: bold;
  margin-bottom: 1rem;
}

.loading {
  display: block;
  grid-area: content;
  align-self: center;
  width: 2rem;
  height: 2rem;
  margin: 5rem auto;
}

.alert-button {
  border-radius: 0.25rem;
  line-height: 0;
  color: var(--c-white);
}

.alert-button svg {
  --indicator-fill: var(--c-primary-red);

  width: 1.5rem;
  height: 1.5rem;
  fill: currentColor;
  stroke: none;
}

@media screen and (max-width: 1200px /*px-vp-hide-nav*/) {
  .alert-button svg {
    color: var(--c-black);
    width: 1.75rem;
    height: 1.75rem;
  }
}
