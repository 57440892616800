

  .container {
    display: grid;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
      'type'
      'text'
      'card';
    padding-top: 0rem;
    width: 100%;
  }

  .type {
    grid-area: type;
    font-weight: var(--fw-medium-bold);
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }

  .text {
    grid-area: text;
    margin-bottom: 1rem;
  }

  .card-list {
    grid-area: card;
  }

  .card {
    height: fit-content;
    width: calc(100% - 2rem);
    --rem-cover-height: 9rem;
    margin-bottom: 1rem;
  }

  .card:last-child {
    margin-bottom: 0rem;
  }

  .alert-action {
    color: var(--c-primary-blue);
    font-weight: var(--fw-bold);
    width: max-content;
  }

  .alert-action a, .alert-action button {
    font-size: var(--fs-body);

    min-width: calc(var(--rem-cover-width) - 1rem);
    background-color: rgba(var(--c-primary-blue-rgb), .05);
    border-radius: var(--form-border-radius);
    border: 1px solid rgba(var(--c-primary-blue-rgb), .1);
    padding: .5rem;
    color: var(--c-primary-blue);
  }
