
  .panel-wrapper {
    transition: height var(--duration) ease;
    overflow: hidden;
    position: relative;
    margin: 0 -1rem; /* focus outlines contained within won't be cut off */
  }

  .panel {
    transition: opacity var(--duration);
    opacity: 1;
    padding: 0 1rem; /* focus outlines contained within won't be cut off */
    overflow: hidden; /* prevent scrollbars from popping up */
  }
