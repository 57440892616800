
.checkbox {
  column-gap: unset;
}

.export-queue-group {
  display: grid;
  grid-template-areas: 'header';
  --grid-gap: 1rem;
  --header-columns: 50px 50px 1fr 100px 100px;
  --group-columns: 50px 1fr;
}

.export-queue-group.expanded {
  grid-template-areas:
    'header           header'
    'group-indicator  annotations';
  grid-template-columns: var(--group-columns);
  gap: var(--grid-gap);
}

.header {
  grid-area: header;
  display: grid;
  grid-template-areas:
    'select expander title count actions';
  grid-template-columns: var(--header-columns);
  gap: var(--grid-gap);
  padding: 16px 0;
}

.header.selected {
  background-color: var(--c-selected);
}

.title {
  grid-area: title;
  color: var(--c-black);
}

.title .subtitle {
  color: var(--c-light-black);
}

.select {
  grid-area: select;
  display: flex;
  justify-content: center;
}

.group-indicator-container {
  grid-area: group-indicator;
  display: flex;
  justify-content: center;
}

.group-indicator-container .group-indicator {
  display: inline-block;
  background-color: var(--c-dark-gray);
  border-radius: var(--form-border-radius);
  width: 2px;
  height: 100%;
}

.expander {
  grid-area: expander;
}

.expander button {
  width: 3ch;
  height: 3ch;
  scroll-margin: 1.5rem;
}

.expander svg {
  transition: transform 150ms ease-in-out;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  transform: translateZ(1px) rotateX(0);
}

.export-queue-group.expanded .expander svg {
  transform: translateZ(1px) rotateX(180deg);
}

.count {
  grid-area: count;
}

.actions {
  grid-area: actions;
  margin-top: -12px;
  padding-right: 6px;
}

.actions > button > svg {
  width: 3ch;
  height: 3ch;
  fill: var(--c-light-black);
  line-height: 0;
  padding: 2px 6px;
}

.annotations {
  grid-area: annotations;
}

.annotations [data-column="highlight"],
.annotations [data-column="note"] {
  min-width: 10ch;
}

.annotations [data-column="citation"],
.annotations [data-column="release"] {
  min-width: 10ch;
  max-width: fit-content;
}

.annotations [data-column="actions"] {
  width: auto;
  text-align: center;
}

.centered {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1470px) {
  .export-queue-group {
    --header-columns: 30px 30px 1fr 100px 75px;
    --group-columns: 30px 1fr;
    --grid-gap: 0.5rem;
  }
}
