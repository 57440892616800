

  .container {
    display: grid;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
      'type'
      'text'
      'card';
    width: 100%;
  }

  .type {
    grid-area: type;
    font-size: 1.25rem;
    font-weight: var(--fw-medium-bold);
    margin-bottom: 0.5rem;
  }

  .text {
    grid-area: text;
    margin-bottom: 1rem;
  }

  .card {
    grid-area: card;
    height: fit-content;
    width: calc(100% - 2rem);
    --rem-cover-height: 9rem;
  }
