
.container {
  display: grid;
  grid-row-gap: 1rem;
  max-width: max-content;
}

.header {
  display: flex;
  align-items: center;
  color: var(--c-light-black);
  font-weight: var(--fw-medium);
}

.header svg {
  width: 1.25rem;
  height: 1.25rem;
  stroke: currentColor;
  margin-right: 0.5rem;
}

.link {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 2rem;
  grid-template-columns: 80px;
  composes: focus-outline from global;
}

.volume-label {
  composes: metadata-label from global;
  color: var(--c-light-black);
  margin-right: 0.5rem;
}

.volume-count {
  font-size: var(--fs-metadata);
}

/* TABLET LAYOUT */

@media screen and (max-width: 1024px /* px-vp-very-wide */) {
  .container {
    max-width: none;
  }

  .container,
  .header {
    justify-content: center;
  }
}
