
.title {
  font-size: var(--fs-medium-head);
  font-weight: var(--fw-bold);
  margin-top: 1rem;
}

.warning {
  color: var(--c-notif-warning);
  background-color: hsla(var(--c-notif-warning), 0.02);
  border: 1px solid var(--c-notif-warning);
  border-radius: 5px;
  margin-bottom: 2rem;
  padding: 8px;
  display: grid;
  grid-template-areas:
    'icon message';
  grid-template-columns: auto 1fr;
  gap: 1rem;
  align-items: center;
}

.warning-icon {
  height: 1.5rem;
  width: 1.8rem;
  fill: var(--c-notif-warning);
  grid-area: icon;
}

.warning-text {
  grid-area: message;
}

.release {
  margin-block-start: 0.75rem;
}

.review-section:not(:first-of-type) {
  margin-block-start: 1rem;
}
.success-icon {
  height: 2rem;
  width: 2rem;
}

.success-icon g {
  stroke: var(--c-notif-success);
}

.failure-icon {
  height: 1.5rem;
  width: 1.5rem;

  stroke: var(--c-notif-error);
  fill: var(--c-notif-error);
}

