
  @import '../../app/views/core/base.less';

  .not-found {
    width: 75%;
    top: 75px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    color: @c-medium-black;

    .header {
      font-weight: bold;
      font-size: @fs-medium-head;
    }

    .message {
      margin: 5px;
    }

    .link {
      margin: 5px;
      .pop(@c-primary-blue);
      color: @c-primary-blue;
    }
  }
