
.row {
  color: var(--c-dark-black);
}

.title-container {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--c-darkest-gray);
  position: sticky;
  top: 0;
  background-color: var(--c-lightest-gray);
  z-index: 1;
}

.title {
  font-weight: var(--fw-bold);
}

.actions {
  padding-top: 0.25rem;
}

.action {
  margin-bottom: 10px;
  color: var(--c-light-black);
  cursor: pointer;
  composes: linked from global;
}

.separator {
  margin: 0 10px 0 6px;
  border-left: 1px solid var(--c-darkest-gray);
}

.toggle {
  color: inherit;
  width: 100%;
  cursor: pointer;
}

.chapter-list {
  padding: 0 3px;
}
