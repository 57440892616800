
.root {
  position: relative;
}

.root > button > svg {
  transition: transform 150ms ease-in-out;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  transform: translateZ(1px) rotateX(0);
}

.root > button[aria-expanded=true] > svg {
  transform: translateZ(1px) rotateX(180deg);
}

.root > ul {
  z-index: 26; /* One higher than the Surface z-index, and lower than the header z-index */
}

.root > ul:focus {
  outline: none;
}

.button {
  display: flex;
  align-content: center;
  border: 1px solid black;
  padding: 5px;
}

.icon {
  width: 1.5em;
  height: 1.5em;
  margin-left: 0.5em;
  flex-shrink: 0;
  stroke: currentColor;
  fill: currentColor;
}

.list {
  position: absolute;
  border: 1px solid black;
  padding: 5px;
}

.highlighted {
  background-color: lightgray;
}

.selected {
  background-color: lightblue;
}

[role="listbox"] > [role="option"] {
  cursor: pointer;
}
