
  @import '../../app/views/core/base.less';

  .form {
    .welcome-panel;

    display: grid;
    grid-gap: 2rem;
    gap: 2rem;
  }

  .logo-container {
    margin: 0 auto;
  }

  .logo {
    height: auto;
  }

  .prompt {
    font-size: @fs-medium-head;
    font-weight: @fw-bold;
    border-top: 1px solid @c-dark-gray;
    padding-top: 2rem;
    text-align: center;

    @media screen and (min-width: @px-vp-wide) {
      white-space: nowrap;
    }
  }

  .input-wrapper {
    position: relative;
    background-color: @c-light-gray;
    border: 1px solid @c-light-gray;
    border-radius: @px-border-radius;
    box-sizing: border-box;
    width: 100%;
    transition: all 0.2s ease-in-out;
    max-height: 3.5rem;

    &.active {
      background-color: transparent;
      border: 1px solid @c-dark-gray;

      .library-code-label {
        top: 0.3rem;
        left: 1rem;
        font-size: @fs-mini;
        color: @c-light-black;
      }

      .library-code {
        padding: 1.7rem 1rem 0.3rem;
      }
    }

    &.focus {
      box-shadow: inset 0 0 0 3px @c-white, 0 0 0 3px @c-focus;
    }
  }

  .library-code-label {
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-size: @fs-body;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  .library-code {
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    font-size: @fs-med;
    cursor: pointer;
  }

  .error-message {
    color: @c-primary-red;
    font-size: @fs-mini;
    margin-top: 0.5rem;
    padding: 0 10px;
  }

  .submit {
    display: block;
    width: 100%;
    background: @c-primary-red;
    border-radius: @px-border-radius;
    border: none;
    color: @c-white;
    font-family: @ff-sans;
    font-size: @fs-med;
    font-weight: @fw-bold;
    padding: 1rem;
    text-shadow: none;
    text-transform: uppercase;
  }

  @media screen and (max-width: @px-vp-narrow) {
    .logo {
      max-width: 100%;
    }
  }
