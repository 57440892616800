
@import '../../app/views/core/base.less';

@rem-cover-height: 20rem;
@rem-cover-width: 3 / 4 * @rem-cover-height;

.container {
  text-align: left;
  max-width: @rem-cover-width;
}

.wrapper {
  height: 10ch;
}

.info {
  display: grid;
  position: relative;
  grid-row-gap: 1rem;
  margin-bottom: 1rem;
}

.info:focus-within::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  box-shadow: inset 0 0 0 2px @c-dark-black, 0 0 0 3px @c-focus;
  border-radius: 0.25rem;
}

.jacket {
  width: @rem-cover-width;
  height: @rem-cover-height;
  border: 1px solid rgba(var(--c-dark-black-rgb), .1);
  border-bottom: 0;
  box-sizing: border-box;
}

.cover {
  width: 100%;
  height: 100%;
}

.biblio:focus {
  outline: none;
  box-shadow: none;
}

.title {
  font-weight: var(--fw-bold);
  margin: 0 0 0.25rem;
  .lineClamp(2);
  font-size: var(--fs-medium);
}

.subtitle {
  margin: 0 0 0.25rem;
  .lineClamp;
}

.actions {
}

.action {

  a, button {
    color: var(--c-darkest-blue);
    font-weight: var(--fw-bold);
    font-size: var(--fs-body);

    border: 1px solid rgba(var(--c-white-rgb), .5);
    border-radius: var(--form-border-radius);
    .focus-outline-dark;

    margin: 0 0 1rem;
    padding: .75rem 1rem;

    background-color: rgba(var(--c-white-rgb), 1);

    min-width: calc(@rem-cover-width - 2.125rem);
  }
}

.actions-right {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0; // Hack to avoid whitespace in between items
  text-align: center;
  opacity: .75;

  div {
    display: inline;
  }

  svg {
    stroke: var(--c-white);
    height: 1.25rem;
    border-radius: var(--form-border-radius);
  }

  :global(.metadata) {
    padding-left: .5rem;
    font-size: var(--fs-body);
  }
}
