html, body {
  // Don't highlight clicks in iOS browsers:
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  // Don't let iOS browsers increase the text size for "readability":
  -webkit-text-size-adjust: none;

  // Disable the tap-and-hold contextual popover in iOS:
  -webkit-touch-callout: none;

  // Make font weight consistent throughout.
  // The MuseoSlabFree font should not look bold by default.
  -webkit-font-smoothing: antialiased;

  // Disable zoom in Microsoft browsers:
  -ms-content-zooming: none;
  -ms-content-zoom-chaining: chained;
  -ms-content-zoom-limit-min: 100%;
  -ms-content-zoom-limit-max: 100%;
}

html, body {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  color: @c-fg;
  font-family: @ff-sans;
  line-height: @lh-body;
  font-size: @fs-body;
  font-weight: @fw-regular;
}

strong {
  font-weight: bold;
}

#app {
  isolation: isolate;
}

.head {
  font-family: @ff-head;
  font-size: @fs-head;
  font-weight: normal;
}

.loud {
  font-family: @ff-head;
  font-size: @fs-loud;
}

.large-heading {
  font-size: @fs-large-head;
  font-weight: @fw-bold;
}

.medium-heading {
  font-size: @fs-medium-head;
  font-weight: @fw-medium;
}

.small-heading {
  font-size: @fs-small-head;
  font-weight: @fw-regular;
}

.large-body-heading {
  font-size: @fs-large-body-head;
  font-weight: @fw-medium;
}

.small-body-heading {
  font-size: @fs-small-body-head;
  font-weight: @fw-medium;
  color: @c-dark-black;
}

.metadata,
.metadata-label {
  font-size: @fs-metadata;
  line-height: @lh-body;
  font-weight: @fw-regular;
}

.metadata-label {
  text-transform: uppercase;
}

.button-text {
  font-size: @fs-button;
  line-height: @lh-button;
  font-weight: @fw-bold;
}

.link-text {
  font-size: @fs-link;
  line-height: @lh-link;
  font-weight: @fw-bold;
}

button {
  cursor: pointer;

  .focus-outline;

  &.dark {
    .focus-outline-dark;
  };
}

.visually-hidden {
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}

.amp {
  font-family: @ff-ampersand;
  font-style: italic;
  font-weight: normal;
  line-height: 1;
}

.nota-bene {
  font-variant: small-caps;
  font-weight: bold;
  font-size: 89%;
}

.tappable {
  cursor: pointer;
  // This gets rid of the "300ms click delay" in modern
  // mobile web browsers:
  touch-action: manipulation;
}

a {
  color: inherit;
  text-decoration: none;
}

b {
  font-weight: 600;
}

code {
  font-family: @ff-mono;
}

@media screen and (max-width: @px-vp-narrow) {
  .head {
    font-size: @fs-loud-vp-narrow;
  }
}

// Adjust for really wide screens

@media screen and (min-width: @px-vp-max) {
  .pillar {
    max-width: @px-vp-max;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .loud {
    font-size: @fs-head;
  }
}
progress {
  -webkit-appearance: none;
  appearance: none;
  height: 3px;

  &[value]::-webkit-progress-bar {
    background-color: @c-white;
    border-radius: 3px;
  }

  &[value]::-webkit-progress-value {
    background-color: @c-primary-blue;
    transition: width 2.5s ease-in-out;
  }
}
