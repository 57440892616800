
.sort {
  margin-left: auto;
}

.sort > div {
  min-width: 150px;
}

@media screen and (max-width: 499px /* px-vp-narrow */) {
  .sort > div {
    min-width: 130px;
  }
}
