
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.shield {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0.2, 0.2, 0.2, 0.2);
  transition: opacity 0.5s;
}

.content {
  position: absolute;

  > * {
    will-change: transform;
    transition: transform 300ms ease, opacity 300ms ease;
  }
}

.enter, .leave-to {
  .shield {
    opacity: 0;
  }
}

.leave-to:not(.none) {
  transition: transform 300ms ease, opacity 300ms ease;
}

.right {
  .content > * {
    transform: translateX(100%);
  }
}

.left {
  .content > * {
    transform: translateX(-100%);
  }
}

.down {
  .content > * {
    transform: translateY(-100%);
  }
}

.up {
  .content > * {
    transform: translateY(100%);
  }
}

.up-gentle {
  .content > * {
    transform: translateY(10%);
    opacity: 0;
  }
}

.spin {
  .content > * {
    transform: translateX(100%) rotateZ(180deg);
  }
}

.stall {
  .content > * {
    transform: translateY(0);
  }
}

.fade {
  .content > * {
    transform: translateY(0);
    opacity: 0;
  }
}
