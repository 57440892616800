
@import '../../app/views/core/base.less';

.container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 0.5rem;
}

@rem-cover-height: 5rem;
@rem-cover-width: 3 / 4 * @rem-cover-height;
.cover {
  height: @rem-cover-height;
  width: @rem-cover-width;
  box-shadow: 0px 3px 6px rgba(34, 34, 34, 0.15);
}

.created {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}

.count {
  font-weight: @fw-bold;
}

.date {
  color: @c-light-black;
  font-size: @fs-metadata;
}

.title {
  .lineClamp(2);
}

.subtitle {
  .lineClamp;
  color: @c-light-black;
}
