
.banner {
  background-color: var(--c-dark-black);
  border-radius: var(--form-border-radius);
  color: var(--c-white);
  display: flex;
  min-height: 4rem;
  margin-bottom: 1rem;
}

.error {
  display: inherit;
  background-color: var(--c-error-orange);
  border-radius: var(--form-border-radius) 0 0 var(--form-border-radius);
}

.error-icon {
  height: 2rem;
  width: 2rem;
  margin: 1rem;
  fill: white;
}

.message {
  margin: 0.5rem 1rem;
  margin-right: auto;
}

.sublabel {
  color: var(--c-darkest-gray);
  font-size: var(--fs-metadata);
}

.progress {
  display: inherit;
  margin-left: auto;
}

.progress-icon {
  height: 2rem;
  width: 2rem;
  margin: 1rem;
}

@media screen and (max-width: 499px) {
  .mobile-complete-banner {
    display: grid;
  }

  .mobile-error-banner {
    display: grid;
    grid-template-columns: auto 1fr;
  }

  .error {
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .mobile-complete-banner .message,
  .mobile-error-banner .message {
    margin-right: 1rem;
  }
}
