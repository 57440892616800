
.form {
  scroll-margin-top: 3rem; /* room for header */
}

.label {
  color: var(--c-medium-black);
}

.dropdowns {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  font-size: var(--fs-medium);
  gap: 0.5rem;
}

.dropdowns > * + * {
  flex-grow: 1;
}

.dropdowns,
.search {
  margin: 0.5rem 0;
}

.scope {
  background-color: var(--c-light-gray);
  color: var(--c-dark-black);
  padding: 1rem;
  border-radius: var(--form-border-radius);
}
