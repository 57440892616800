
.button {
  color: var(--c-white);
  background-color: var(--c-primary-red);
  box-sizing: border-box;
  text-align: center;
  text-transform: uppercase;
  font-weight: var(--fw-bold);
  border-radius: var(--form-border-radius);
  padding: 1rem;
  transition: opacity 150ms;
}

.button.large {
  width: 100%;
}

.button.small {
  padding: 0.5rem;
}

.button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
