
.container {
  max-width: max-content;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "checkbox label"
    "unused   description";
  column-gap: 1rem;
  row-gap: 0.25rem;
  align-items: center;
}

.input {
  grid-area: checkbox;

  border-radius: 0.25rem;
  border: 2px solid var(--c-dark-gray);
  width: 1.5rem;
  height: 1.5rem;
  box-sizing: border-box;
  background-color: var(--c-white);
  margin: 0;
  cursor: pointer;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input:disabled {
  cursor: not-allowed;
  background-color: var(--c-light-gray);
}

.input:disabled + .checkmark {
  fill: var(--c-darkest-gray);
}

.input:disabled ~ .label {
  cursor: not-allowed;
}

.checkmark {
  grid-area: checkbox;
  pointer-events: none;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkmark svg {
  width: 1.25rem;
  height: 1.25rem;
}

.label {
  grid-area: label;
  line-height: 1;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.description {
  grid-area: description;
}
