
.container {
  display: grid;
  grid-row-gap: 15px;
}

.dropdown {
  max-width: max-content;
}

.header {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--c-darkest-gray);
  position: sticky;
  top: 0;
  background-color: var(--c-lightest-gray);
  z-index: 1;
}

.title {
  font-weight: var(--fw-bold);
  margin-bottom: 0.5rem;
}
