
.actions {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 1.5rem;
}

.breadcrumbs {
  margin-left: -0.5rem;
}

.title {
  margin-top: 1.5rem;
  font-size: var(--fs-large-head);
  font-weight: var(--fw-bold);
  line-height: 1.25;
}

.subtitle {
  line-height: 1.25;
  margin-top: 0.5rem;
}
