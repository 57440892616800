
@import '../../app/views/core/base.less';

.fields > * + * {
  margin-top: 1.5rem;
}

.help-link {
  .pop-text();
  margin-left: auto;
  margin-right: auto;
  padding: 0.25rem;
  display: block;
  max-width: max-content;
  color: @c-light-black;
  .focus-outline;
}
