
.status-block {
  padding-bottom: 1rem;
}

.list-item {
  transition: opacity .5s ease, transform .5s ease;
}

.list-leave-active {
  position: absolute;
  width: calc(100% - 2rem);
}

.list-enter,
.list-enter-from,
.list-leave-to {
  opacity: 0;
}
