
.filter {
  align-items: center;
  background-color: var(--c-white);
  border-radius: var(--form-border-radius);
  border: 1px solid var(--c-dark-gray);
  box-shadow: 0 1px 6px 0 var(--c-shadow);
  display: flex;
  padding: 0.5rem 1rem;
  position: relative;
}

.text {
  margin-right: 0.25rem;
}

.icon {
  height: 1.25rem;
  width: 1.25rem;
}

.count {
  background-color: var(--c-primary-gray);
  border-radius: 50%;
  color: var(--c-white);
  font-size: var(--fs-metadata);
  height: 1.5rem;
  line-height: 1.5rem;
  position: absolute;
  right: -0.75rem;
  top: -0.75rem;
  width: 1.5rem;
}

@media screen and (max-width: 499px /* px-vp-narrow */) {
  .icon {
    height: 1.5rem;
    width: 1.5rem;
  }
}
