

.page {
  /* We want the header and content sections to extend to the edge of the displayed area,
   * so we don't want to pad the page directly.
   * But we want them to pad content from the edges consistently, so use CSS variables.
   */
  --page-padding-horizontal: 2rem;
  --page-padding-vertical: 2rem;

  display: flex;
  flex-direction: column;
  flex: 1;
}

@media screen and (max-width: 499px /* px-vp-narrow */) {
  .page {
    --page-padding-horizontal: 1rem;
    --page-padding-vertical: 2rem;
  }
}

.header {
  background-color: var(--c-dark-blue);
  color: var(--c-white);
  padding: var(--page-padding-vertical) var(--page-padding-horizontal) 1.25rem;
  border-radius: .5rem .5rem 0 0;
}

.toolbar {
  background: var(--c-lightest-gray);
  box-shadow: 0px 1px 6px 0px var(--c-shadow);
  padding: 1.25rem var(--page-padding-horizontal);
  display: flex;
  gap: 10px;
}

.content {
  padding: var(--page-padding-vertical) var(--page-padding-horizontal);
  background-color: var(--c-white);
  border-radius: 0 0 .5rem .5rem;
  flex: 1;
}
