

  .tag-actions-menu {
    display: flex;
    padding: 0.25rem;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
  }

  .tag-actions-menu svg {
    fill: var(--c-white);
    width: 1.5rem;
    height: 1.5rem;
  }

  .tag-action-button {
    display: block;
    text-decoration: underline;
    text-underline-offset: 0.25rem;
    padding: 0.25rem 0;
  }

  .action-menu {
    max-width: 12.5rem;
  }


  .action-actions {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: min-content min-content;
  }

  .rename-input {
    color: var(--c-medium-black);
    background: var(--c-light-gray);
    border: 1px solid var(--c-light-gray);
    padding: 0.625rem 0.5rem;
    font-size: 1rem;
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
  }

  .rename-warning {
    margin-bottom: -0.5rem;
  }
