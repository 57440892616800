
.loading {
  width: 100%;
  height: 10rem;
  margin: auto;
  position: relative;
}

.loading svg {
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.volume:not(:last-child) {
  margin-bottom: 1.5rem;
}
