
@import '../../app/views/core/base.less';

.pagination {
  display: grid;
  grid-template-areas: "previous pages next";
  grid-template-columns: 1fr @px-vp-very-narrow 1fr;
  align-items: center;
  justify-items: center;

  @media screen and (max-width: @px-vp-tablet) {
    grid-template-columns: auto;
    grid-template-areas:
    "previous"
    "pages"
    "next";
  }
}

.bookend {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.bookend.left {
  justify-self: end;
  grid-area: previous;

  @media screen and (max-width: @px-vp-tablet) {
    margin-right: 1.5rem;
    margin-bottom: 0.6rem;
    justify-self: center;
  }
}

.bookend.right {
  justify-self: start;
  grid-area: next;

  @media screen and (max-width: @px-vp-tablet) {
    margin-left: 1.5rem;
    margin-top: 0.2rem;
    justify-self: center;
  }
}

.pages {
  grid-area: pages;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.page {
  border-radius: var(--form-border-radius);
  font-variant-numeric: tabular-nums;
  text-decoration: underline;
  text-underline-position: under;
  height: 2.5ch;
  min-width: 2.5ch;
  padding: 0.25rem;
  box-sizing: content-box;
  line-height: 1;
  color: var(--c-primary-blue);
}

.page.ends {
  text-align: center;
  margin-top: 0.4rem;
}

.page.active {
  text-decoration: none;
  font-weight: bold;
  color: var(--c-black);
  background-color: var(--c-light-gray);
}

.shift-button {
  fill: var(--c-primary-blue);
  display: flex;
  height: 2.5ch;
  width: 2.5ch;
  padding: 0.25rem;
}

.separator {
  display: inline-block;
  text-align: center;
  min-width: 3ch;
}
