
.container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr auto;
  height: 100%;
}

.content-container {
  padding-bottom: 0;
  overflow-y: auto;
  grid-column: 1;
  grid-row: 1;
  margin-bottom: 0.25rem;
}

.section {
  margin: 2rem 1rem;
}

.section:first-child {
  margin-top: 1rem;
}

.header {
  font-size: var(--fs-medium);
  font-weight: var(--fw-medium);
  color: var(--c-medium-black);
  margin-bottom: 0.5rem;
}

.additional {
  color: var(--c-light-black);
  margin-top: 0.5rem;
}

.card {
  /* keep consistent with dropdown and .destination */
  border-radius: 0.25rem;
  border-color: var(--c-dark-gray);
}

.dropdown button {
  border: 1px solid var(--c-light-gray); /* add border to keep text vertically in line with .card and .destination */
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto auto;
}

.dropdown .badge-with-label { /* need to override global badge values */
  background-color: var(--c-dark-gray); /* contrast with FormSelect background-color */
  margin-left: auto;
  margin-right: 0.5rem;
}

.destination {
  composes: shadow-box from global;
  padding: 1rem;
 display: grid;
 grid-template-columns: 1fr auto;
}

.destination .badge-with-label { /* need to override global badge values */
  background-color: var(--c-dark-gray); /* match with one in dropdown */
}

.option-badge {
  composes: badge from global;
}

.apply-container {
  bottom: 0;
  position: sticky;
  border-top: 2px solid var(--c-light-gray);
  background-color: var(--c-white);
  padding: 2rem 1rem 1rem;
  grid-column: 1;
  grid-row: 2;
}

.apply-inactive {
  opacity: .6;
  cursor: default;
}

@media screen and (max-width: 360px) {
  .apply-container {
    padding: 1rem 1rem 0;
  }
}

/* Dropdown overrides */

.option {
  color: var(--c-dark-black);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.highlighted {
  background-color: var(--c-dark-black);
  color: var(--c-light-gray);
  composes: dark from global; /* to get the badge to be dark */
}

.option:hover {
  background-color: var(--c-dark-black);
  color: var(--c-light-gray);
}

.option:hover .option-badge {
  /* explicitly write out the dark badge styles, since it won't allow composes: dark from global; */
  color: var(--c-light-gray);
  background-color: var(--c-dark-badge);
  display: inline-block;
  padding: 5px;
  font-size: var(--fs-metadata);
  font-weight: var(--fw-medium);
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
}
