
.context-menu-button {
  display: flex;
  padding: 0.25rem;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
}

.context-menu-button svg {
  fill: var(--c-white);
  width: 1.5rem;
  height: 1.5rem;
}

