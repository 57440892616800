@import '../core/base.less';

.bifocal-view {
  position: absolute;
  top: -200%;
  right: 0;
  height: 100%;
  left: 0;
  opacity: 0;
  composes: z-bifocal-view from global;
}

.bifocal-view iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  visibility: hidden;
}

:global .data-book_ready {
  :local {
    .bifocal-view {
      top: 0;
      opacity: 1;
      transition: opacity 200ms linear;
    }
    .bifocal-view iframe {
      pointer-events: auto;
      visibility: visible;
    }
  }
}
