
.toast {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  min-height: 4rem;
  line-height: normal;
  color: var(--c-white);
  display: flex;
  align-items: center;
  padding: 0 2rem;

  display: grid;
  grid-template-areas:
    'icon message dismiss';
  grid-template-columns: auto 1fr auto;
  gap: 1rem;
}

@media screen and (max-width: 499px) {
  .toast {
    display: grid;
    grid-template-areas:
      'icon'
      'message'
      'dismiss';
    grid-template-columns: auto;
    justify-items: center;
    gap: 0.75rem;
    padding: 1rem;
  }
}
.toast svg {
  stroke: currentColor;
  fill: currentColor;
  height: 1.25rem;
  width: 1.25rem;
  grid-area: 'icon';
}

.toast.animate {
  animation: fadein 500ms, fadeout 1s 4s forwards;
}

.toast.success {
  background-color: var(--c-notif-success);
}

.toast.error {
  background-color: var(--c-notif-error);
}

.toast.info {
  color: var(--c-black);
  background-color: var(--c-notif-info);
}

.message {
  grid-area: 'message';
}

.dismiss {
  grid-area: 'actions';
  border: 1px solid;
  border-radius: var(--form-border-radius);
  padding: 0.5rem 1rem;
}

@-webkit-keyframes fadein {
  to { opacity: 1; }
  from { opacity: 0; }
}

@keyframes fadein {
  to { opacity: 1; }
  from { opacity: 0; }
}

@-webkit-keyframes fadeout {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes fadeout {
  from { opacity: 1; }
  to { opacity: 0; }
}
