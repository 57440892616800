

  .alert {
    display: grid;
    grid-template-areas: 'metadata delete';
    grid-template-columns: 1fr auto;
    gap: 1rem;

    padding: 0.5rem;
    border-radius: var(--form-border-radius);
  }

  .alert.unreviewed {
    background-color: var(--c-light-gray);
  }

  .metadata {
    grid-area: metadata;
    display: grid;
    grid-template-areas: 'cover info';
    grid-template-columns: 3.5rem 1fr;
    gap: 1rem;
    padding: 0.5rem;
    margin: -0.5rem;
  }

  .title-cover {
    grid-area: cover;
    width: 3.375rem;
    height: 4.5625rem;
  }

  .series-cover {
    grid-area: cover;
    position: relative;
  }

  .info {
    grid-area: info;
    display: grid;
    grid-template-areas:
      'type'
      'title'
      'subtitle';
    grid-template-rows: 1.5rem 1.5rem 1.5rem;
    text-align: left;
  }

  .type {
    grid-area: type;
    font-weight: var(--fw-medium-bold);
  }

  .title {
    grid-area: title;
  }

  .subtitle {
    grid-area: subtitle;
    color: var(--c-medium-black);
  }

  .fullButton {
    position: absolute;
    height: calc(100% - 1rem);
    width: calc(100% - 2.5rem);
    left: 0;
    top: 0.5rem;
  }

  .delete {
    grid-area: delete;
    height: fit-content;
    padding: 0.25rem 0.25rem 0rem 0.25rem;
  }

  .delete svg {
    height: 1rem;
    width: 1rem;
  }

  .truncated {
    --line-clamp: 1;
    composes: line-clamp from global;
  }

  @media screen and (max-width: 360px /*px-vp-very-narrow*/) {
    .info {
      grid-template-rows: auto 1.5rem 1.5rem;
    }
  }

