

.shield {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 200;
}

.toast {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  min-height: 4rem;
  line-height: normal;
  color: var(--c-white);
  display: flex;
  align-items: center;
  padding: 0 2rem;
  z-index: 200;

  display: grid;
  grid-template-areas:
    'icon message actions';
  grid-template-columns: auto 1fr auto;
  gap: 1rem;
}

@media screen and (max-width: 499px) {
  .toast {
    display: grid;
    grid-template-areas:
      'icon'
      'message'
      'actions';
    grid-template-columns: auto;
    justify-items: center;
    gap: 0.75rem;
    padding: 1rem;
  }
}


.toast svg {
  stroke: currentColor;
  fill: currentColor;
  height: 1.25rem;
  width: 1.25rem;
  grid-area: 'icon';
}

.toast.fadeIn {
  animation: fadein 500ms;
}

.toast.success {
  background-color: var(--c-notif-success);
}

.toast.error {
  background-color: var(--c-notif-error);
}

.toast.info {
  color: var(--c-black);
  background-color: var(--c-notif-info);
}

.message {
  grid-area: 'message';
}

.actions {
  display: flex;
  gap: 1rem;
  grid-area: 'actions';
}

.actions > button {
  border: 1px solid;
  border-radius: var(--form-border-radius);
  padding: 0.5rem 1rem;
}

@-webkit-keyframes fadein {
  to { opacity: 1; }
  from { opacity: 0; }
}

@keyframes fadein {
  to { opacity: 1; }
  from { opacity: 0; }
}
