
@import '../../app/views/core/base.less';

.breadcrumb-container {
  .ellipsis;
  padding: 0.5rem;
}

.breadcrumb-list {
  display: flex;
  align-items: center;
}

.arrow {
  height: 18px;
  fill: white;
  margin: 0 4px -3px 0;
}

.label {
  line-height: normal;
  padding: 0.25rem;

  &:focus {
    .standard-outline-dark;
  }

  span {
    text-decoration: underline;
    text-underline-offset: 0.25rem;
  }
}
