

.list-container {
  background-color: var(--c-white);
  border-radius: 0 0 .5rem .5rem;
}

.tag {
  display: flex;
  border: 1px solid rgba(var(--c-primary-blue-rgb), .1);
  border-radius: var(--form-border-radius);
  color: var(--c-primary-blue);
  margin-block-end: 1rem;
  padding: 1rem;
}

.tag :global(.badge) {
  color: var(--c-primary-blue);
  min-width: fit-content;
}

.link {
  font-weight: var(--fw-bold);
  text-decoration: underline;
  composes: ellipsis from global;
}

.text-filter {
  margin-left: auto;
  max-width: 16rem;
}

