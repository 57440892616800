
.export-queue-group-mobile {
  --buffer: 0.5rem;
  display: grid;
  grid-template-areas:
    'header';
  grid-template-columns: auto;
}

.export-queue-group-mobile.selectable {
  grid-template-areas:
    'checkbox header';
  grid-template-columns: 30px 1fr;
}

.export-queue-group-mobile.expanded {
  grid-template-areas:
    'header          header'
    'group-indicator annotations';
  grid-template-columns: 30px 1fr;
}

.export-queue-group-mobile.expanded.selectable {
  grid-template-areas:
    'checkbox          header'
    'group-indicator annotations';
}


.checkbox {
  grid-area: checkbox;
  padding: var(--buffer) 0;
  margin-bottom: var(--buffer);
}

.header-mobile {
  grid-area: header;
  display: grid;
  grid-template-columns: 30px 1fr;
  gap: 0.5rem;
  text-align: left;
  scroll-margin: 0.25rem;
  padding: var(--buffer) var(--buffer) var(--buffer) 0;
  margin-bottom: var(--buffer);
}

.selected {
  background-color: var(--c-selected);
}

.expander {
  height: 30px;
  width: 30px;
  transition: transform 150ms ease-in-out;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  transform: translateZ(1px) rotateX(0);
}

.export-queue-group-mobile.expanded .expander {
  transform: translateZ(1px) rotateX(180deg);
}

.title {
  color: var(--c-black);
  --line-clamp: 2;
  composes: line-clamp from global;
}

.subtitle {
  color: var(--c-light-black);
  --line-clamp: 1;
  composes: line-clamp from global;
}

.group-indicator-container {
  grid-area: group-indicator;
  display: flex;
  justify-content: center;
  padding: 0.25rem var(--buffer) 0 0;
}

.group-indicator-container .group-indicator {
  display: inline-block;
  background-color: var(--c-dark-gray);
  border-radius: var(--form-border-radius);
  width: 2px;
  height: 100%;
}

.annotations {
  grid-area: annotations;
}
