html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video,
button {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
ins {
  text-decoration: none;
}
del {
  text-decoration: line-through;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button {
  background-color: transparent;
  color: inherit;
}
.shield {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.001);
}
.shield.dark {
  background: rgba(0, 0, 0, 0.6);
}
.standard-outline {
  outline: 3px solid transparent;
  outline-offset: 3px;
  box-shadow: inset 0 0 0 3px #FFFFFF, 0 0 0 3px #FFDF33;
  border-radius: 8px;
}
.standard-outline-dark {
  outline: 3px solid transparent;
  outline-offset: 3px;
  box-shadow: inset 0 0 0 2px #212121, 0 0 0 3px #FFDF33;
  border-radius: 8px;
}
.focus-outline:focus {
  outline: 3px solid transparent;
  outline-offset: 3px;
  box-shadow: inset 0 0 0 3px #FFFFFF, 0 0 0 3px #FFDF33;
  border-radius: 8px;
}
.focus-outline:active {
  box-shadow: none;
}
.focus-outline-dark:focus {
  outline: 3px solid transparent;
  outline-offset: 3px;
  box-shadow: inset 0 0 0 2px #212121, 0 0 0 3px #FFDF33;
  border-radius: 8px;
}
.focus-outline-dark:active {
  box-shadow: none;
}
.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(390px, 1fr));
  grid-gap: 1.5rem;
}
@media screen and (min-width: 1840px) {
  .card-grid {
    grid-template-columns: repeat(3, minmax(390px, 1fr));
  }
}
@media screen and (max-width: 499px) {
  .card-grid {
    grid-template-columns: 1fr;
  }
}
.toolbar {
  display: flex;
  align-items: center;
  background-color: #F8F8F8;
  padding: 20px 2rem;
}
@media screen and (max-width: 499px) {
  .toolbar {
    padding: 0.75rem 1.25rem;
  }
}
.welcome-panel {
  box-shadow: 0 0.625rem 1.25rem #DDDDDD;
  border-radius: 0.25rem;
  margin: 1rem;
  padding: 3rem 4.5rem;
  width: 35rem;
  box-sizing: border-box;
}
@media screen and (max-width: 768px) {
  .welcome-panel {
    margin: 0;
    border: none;
    box-shadow: none;
    padding: 1rem;
    width: 30rem;
  }
}
@media screen and (max-width: 499px) {
  .welcome-panel {
    width: 100%;
  }
}
.no-scrollbar {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}
.no-scrollbar::-webkit-scrollbar {
  /* WebKit */
  width: 0 !important;
  height: 0!important;
  display: none;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.word-break {
  /*
   * break-word is not supported everywhere,
   * and break-all is an acceptable (if worse) fallback.
   * overflow-wrap seems to have even less support,
   * but is apparently preferable to word-break.
   * https://developer.mozilla.org/en-US/docs/Web/CSS/word-break#Values
   */
  word-break: break-all;
  word-break: break-word;
  overflow-wrap: break-word;
}
.surface {
  z-index: 20;
}
.elastiguard {
  z-index: 21;
}
.surface.active {
  z-index: 25;
}
.home-title-card,
.accordion-checkbox {
  z-index: 27;
}
.home-title-card .card-circ-button,
.search-title-card-resultscontainer,
.accordion-body {
  z-index: 28;
}
.home-title-card .card-circ-button .show-all,
.search-title-card-resultscontainer .show-all,
.accordion-body .show-all,
.home-title-card .card-circ-button .show-all-label,
.search-title-card-resultscontainer .show-all-label,
.accordion-body .show-all-label {
  z-index: 29;
}
.library-home .list-preferences-button {
  z-index: 50;
}
.autocomplete-datalist {
  z-index: 55;
}
.sidebar-overlay,
.sidebar {
  z-index: 60;
}
.error-dialog {
  z-index: 65;
}
.toast > div,
.prompt > div {
  z-index: 65;
}
.z-bifocal-view {
  z-index: 70;
}
.menu-shield {
  z-index: 100;
}
.overlay {
  z-index: 100;
}
.data-book_opening .loading-box,
.data-book_reopening .loading-box {
  z-index: 1000;
}
.onboarding-surface .offline-container {
  z-index: 1000;
}
html,
body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: none;
  -webkit-touch-callout: none;
  -webkit-font-smoothing: antialiased;
  -ms-content-zooming: none;
  -ms-content-zoom-chaining: chained;
  -ms-content-zoom-limit-min: 100%;
  -ms-content-zoom-limit-max: 100%;
}
html,
body {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
body {
  color: #111;
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 400;
}
strong {
  font-weight: bold;
}
#app {
  isolation: isolate;
}
.head {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 28px;
  font-weight: normal;
}
.loud {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 24px;
}
.large-heading {
  font-size: 35px;
  font-weight: 700;
}
.medium-heading {
  font-size: 25px;
  font-weight: 500;
}
.small-heading {
  font-size: 20px;
  font-weight: 400;
}
.large-body-heading {
  font-size: 18px;
  font-weight: 500;
}
.small-body-heading {
  font-size: 16px;
  font-weight: 500;
  color: #212121;
}
.metadata,
.metadata-label {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
}
.metadata-label {
  text-transform: uppercase;
}
.button-text {
  font-size: 18px;
  line-height: 1.25;
  font-weight: 700;
}
.link-text {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 700;
}
button {
  cursor: pointer;
}
button:focus {
  outline: 3px solid transparent;
  outline-offset: 3px;
  box-shadow: inset 0 0 0 3px #FFFFFF, 0 0 0 3px #FFDF33;
  border-radius: 8px;
}
button:active {
  box-shadow: none;
}
button.dark:focus {
  outline: 3px solid transparent;
  outline-offset: 3px;
  box-shadow: inset 0 0 0 2px #212121, 0 0 0 3px #FFDF33;
  border-radius: 8px;
}
button.dark:active {
  box-shadow: none;
}
.visually-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.amp {
  font-family: "Baskerville", "Goudy Old Style", "Palatino", "Book Antiqua", "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: normal;
  line-height: 1;
}
.nota-bene {
  font-variant: small-caps;
  font-weight: bold;
  font-size: 89%;
}
.tappable {
  cursor: pointer;
  touch-action: manipulation;
}
a {
  color: inherit;
  text-decoration: none;
}
b {
  font-weight: 600;
}
code {
  font-family: "Consolas", "Menlo", "Monaco", "Andale Mono", monospace;
}
@media screen and (max-width: 499px) {
  .head {
    font-size: 22px;
  }
}
@media screen and (min-width: 1366px) {
  .pillar {
    max-width: 1366px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .loud {
    font-size: 28px;
  }
}
progress {
  -webkit-appearance: none;
  appearance: none;
  height: 3px;
}
progress[value]::-webkit-progress-bar {
  background-color: #FFFFFF;
  border-radius: 3px;
}
progress[value]::-webkit-progress-value {
  background-color: #006EBB;
  transition: width 2.5s ease-in-out;
}
.native-scrollable {
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.data-scrollfocus_popover .native-scrollable,
.data-scrollfocus-picker .native-scrollable,
.data-scrollfocus_update .native-scrollable {
  -webkit-overflow-scrolling: initial !important;
}
.data-scrollfocus_popover .popover-scroller {
  -webkit-overflow-scrolling: touch !important;
}
.u-hide {
  display: none !important;
}
.badge {
  color: #777777;
  background-color: #F0F0F0;
  display: inline-block;
  padding: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
}
