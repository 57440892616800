
.header {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--c-dark-gray);
  background-color: var(--c-white);
}

.title {
  font-weight: var(--fw-bold);
  font-size: var(--fs-large-body-head);
}

.subtitle {
  color: var(--c-light-black);
}

.metadata {
  text-align: left;
  margin: 0.25rem 0;
}

.metadata th {
  padding-right: 1rem;
  color: var(--c-light-black);
}
