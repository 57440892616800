
@import '../../app/views/core/base.less';

.message {
  margin: 0.25rem;

  a {
    .pop-text(@c-primary-blue);
    color: @c-primary-blue;
    .focus-outline();
  }
}
