
.card {
  max-width: 35ch;
}

.limit {
  border-left: 0.25rem solid var(--c-dark-gray);
  padding-left: 1ch;
  color: var(--c-medium-black);
}

.actions {
  display: flex;
  gap: 1.25rem;
  flex-wrap: wrap;
  align-items: center;
}

.cancel {
  text-transform: uppercase;
  color: var(--c-medium-black);
  composes: linked from global;
}

