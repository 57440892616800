
@import '../../app/views/core/base.less';

.container {
  border-top-left-radius: 1rem;
  padding: 1rem;
  background-color: @c-dark-black;
  color: @c-white;
  min-width: 25rem;
  max-width: 100%;
  box-sizing: border-box;

  position: fixed;
  bottom: 0;
  right: 0;
}

.header {
  font-weight: @fw-bold;
}

.input {
  border: 1px solid @c-dark-gray;
  border-radius: 0.5rem;
  padding: 0.75rem;
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-size: @fs-body;
  background-color: @c-medium-black;
  border: 0;
  color: inherit;
  margin-top: 1rem;
}

.output {
  background-color: @c-medium-black;
  border-radius: 0.5rem;
  width: 100%;
  transition: background 200ms ease;
  text-align: left;
  position: relative;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 1rem;

  &.copied {
    background-color: #3f7a94;
  }

  pre {
    max-height: 300px;
    overflow: auto;
    padding: 0.75rem;
  }
}

.output-header {
  display: flex;
  justify-content: space-between;

  padding: 0.5rem 0.75rem;
  background-color: @c-light-black;
}

.copy {
  display: flex;
  align-items: center;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    fill: @c-white;
    margin-right: 0.5rem;
  }
}

@media screen and (max-width: @px-vp-narrow) {
  .container {
    min-width: unset;
    width: 100%;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    border-top-left-radius: 0;
    bottom: unset;
    right: unset;
  }
}
