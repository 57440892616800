
  @import '../../app/views/core/base.less';

  @gutter: 2rem;
  @dotSize: 0.75rem;

  // IF YOU CHANGE THIS,
  // YOU NEED TO CHANGE `slideWidth` IN SETUP
  @slide-width: calc(224px + 5rem);

  .container {
    grid-row: 1;
    grid-column: 1 / span 2;

    display: grid;
    box-sizing: border-box;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr auto;

    background-color: var(--c-dark-blue);
    color: @c-white;
    border-radius: .5rem;

    &> h1 {
      font-size: @fs-large-head;
      font-family: @ff-head;
      font-weight: @fw-bold;
      line-height: 1;

      grid-row: 1;
      grid-column: 1;

      padding: 2 * @gutter 0 0 @gutter;
    }
  }

  @media screen and (max-width: @px-vp-narrow) {
    .container > h1 {
      font-size: @fs-medium-head;
      padding: @gutter 0 0 @gutter / 2;
    }
  }

  .carousel {
    grid-row: 2;
    grid-column: 1 / span 2;
    box-sizing: border-box;
    position: relative;
    overscroll-behavior: contain;
    overflow-x: auto;

    .carousel-list {
      display: flex;
      overflow-x: scroll;
      .no-scrollbar;
      scroll-snap-type: x mandatory;
      min-height: 267px; // CALCULATED FROM CARD HEIGHT

      li {
        padding: 2rem @gutter 3rem;
        scroll-snap-align: start;
        box-sizing: border-box;
        padding-top: 3.375rem;
      }

      li:not(:last-child) {
        min-width: @slide-width;
        width: @slide-width;
        max-width: @slide-width;
        display: flex;
      }

      @media screen and (max-width: @px-vp-narrow) {
        li {
          padding: @gutter 1rem 3rem;
        }
      }

    }
  }

  .empty-card {
    width: @slide-width;
  }

  .curtain {
    background: linear-gradient(90deg, rgba(14,42,67,0) 0%, rgba(14,42,67,0.5) 50%, rgba(14,42,67,1) 100%);
    opacity: 0.9;
    position: absolute;
    top: 1.5rem;
    bottom: 0;
    right: 0;

    pointer-events: none;
  }

  .carousel-actions {
    background-color: var(--c-darkest-blue);
    color: @c-white;
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    align-items: center;
    line-height: 0;
    position: relative;
    grid-column: 1 / end;
    padding: .75rem 0;

    @media screen and (max-width: @px-vp-narrow) {
      justify-content: space-between;
    }

    .breadcrumbs {
      grid-row: 1;
      grid-column: 2;
      margin: 0 2rem;

      cursor: pointer;
    }

    .dot {
      height: @dotSize;
      width: @dotSize;
      margin: 0 0.25em;
      background-color: @c-darkest-gray;
      border-radius: 50%;
      display: inline-block;
      transition: transform 150ms;
    }

    .current-page {
      background-color: @c-light-gray;
      transform: scale(1.2);
    }

    button {
      &:focus {
        box-shadow: none;
      }

      svg {
        width: 2em;
        height: 3em;
        fill: @c-white;
        padding: 0 0.5em;
      }

      &:disabled svg {
        fill: @c-light-black;
      }
    }
  }

  .recently-read-actions {
    grid-row: 1;
    grid-column: 2;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: @gutter @gutter 0 0;

    .view-all {
      display: inline-block;
      .pop(@c-white);
      .focus-outline-dark;
    }
  }

  .empty-state {
    // override the margin from EmptyState.isCompact=true
    // NOTE: we still want the logo image that comes with isCompact=true
    margin: 2rem auto !important;

    grid-column: 1 / end;
  }
