
.account-dropdown {
  border-radius: 0.25rem;
  line-height: 0;
  color: var(--c-white);
}

.account-dropdown svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: currentColor;
  stroke: none;
}

@media screen and (max-width: 1200px /* px-vp-hide-nav */) {
  .account-dropdown svg {
    color: var(--c-black);
    width: 1.75rem;
    height: 1.75rem;
  }
}
