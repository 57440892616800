
.container {
  --c-header-background: var(--c-lightest-gray);
  --c-body-background: var(--c-lightest-gray);
  background-color: var(--c-body-background);
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr;
}

.container :global(.notch) {
  background-color: var(--c-header-background);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--c-header-background);
  padding: 1rem;
}

.header h1 {
  font-weight: var(--fw-bold);
  display: inline-block;
  vertical-align: text-bottom;
}

.header svg {
  width: 1.25rem;
  height: 1.25rem;
}

.close {
  color: var(--c-light-black);
  padding: 0.25rem;
}

.body {
  overflow-y: auto;
}

/* Firefox doesn't respect padding-bottom
  * on overflow auto/scroll elements.
  *
  * https://bugzilla.mozilla.org/show_bug.cgi?id=748518
  */
.body > * {
  padding: 1rem;
  padding-bottom: 4rem;
}

.emptySpan {
  padding: 0;
}
