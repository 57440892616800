
@import '../../app/views/core/base.less';

.container {
  section {
    padding: 1rem 0;

    &:not(:last-child) {
      border-bottom: 1px solid @c-medium-black;
    }
  }

  a {
    .pop-text();

    .focus-outline;
  }
}

.logos,
.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.links {
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.header {
  font-size: @fs-small-head;
  font-weight: @fw-bold;
  margin-bottom: 1rem;
}

.od-logo {
  width: 226px;
  height: 27px;
}

.ln-logo {
  width: 253px;
  height: 32px;
}

.od-logo,
.ln-logo {
  display: block;
  margin: 20px auto;

  :global(.icon-solid) {
    fill: var(--c-dark-black);
  }
}

.table {
  width: 100%;

  th,
  td {
    padding: 0.5rem;
    vertical-align: top;
  }

  th {
    color: var(--c-darkish-black);
    text-align: left;
  }

  td {
    color: var(--c-dark-black);
  }
}
