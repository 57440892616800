

@import '../../app/views/core/base.less';

.arena {
  opacity: 1;
}

.skip-link {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  composes: focus-outline from global;
}

.skip-link:focus {
  position: fixed;
  left: 0.25rem;
  right: 0.25rem;
  top: 0.25rem;
  width: auto;
  height: auto;
  padding: 1rem;
  background-color: var(--c-primary-red);
  color: var(--c-white);
  z-index: 500;
}

.surfaces {
  display: grid;

  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media screen and (max-width: @px-vp-hide-nav) {
    .header,
    .surface {
      grid-column: 1 / span end;
      transition: transform linear 180ms;
    }

    .nav {
      box-shadow: inset -10px 0 10px @c-shadow;
    }

    &.show-nav {
      .header,
      .surface {
        transform: translateX(@px-nav-width);
      }
    }
  }

  .shield {
    grid-column: 2;
    grid-row: 1 / span end;
    width: 100%;
    height: 100%;
    z-index: 35;
  }
}

.nav {
  grid-column: 1;
  grid-row: 1 / end;
  z-index: 50;
  box-shadow: 10px 0px 10px @c-shadow;
  width: @px-nav-width;
  box-sizing: border-box;
}

.surface {
  grid-row: 2;
  grid-column: 2;
}

.header {
  grid-row: 1;
  grid-column: 2;
  z-index: 30;
  background-color: var(--c-darkest-blue);
}

// LoadingBox overrides.
.arena-title-loading {
  display: none;
}

// At the arena level, the loading component is shown when a title sets specific global classes
:global(.data-book_opening),
:global(.data-book_reopening) {
  .arena-title-loading {
    display: block;
  }
}

:global {
  .forward-enter-active,
  .forward-leave-active,
  .back-leave-active,
  .back-enter-active {
    transition: transform 260ms ease-out;
  }
  .forward-enter-from,
  .back-leave-to {
    transform: translateX(100%);
  }
  .back-enter-from,
  .forward-leave-to {
    transform: translateX(-100%);
  }
  .nav-enter-from,
  .nav-leave-to {
    transform: translateX(-100%);
  }
  .nav-enter-active,
  .nav-leave-active {
    transition: transform 220ms ease-out;
  }
}
