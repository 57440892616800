
@import '../../app/views/core/base.less';

.container > div {
  margin: auto;
}

@media screen and (max-width: @px-vp-very-narrow) {
  .container {
    transform: scale(0.9);

    div {
      max-width: 90vw;
    }
  }
}

@media screen and (max-width: @px-vp-narrow) {
  :global(.recaptcha-challenge) {
    transform: scale(0.7);
    transform-origin: left;
  }
}
