
.loading {
  width: 100%;
  height: 10rem;
  margin: auto;
  position: relative;
}

.loading svg {
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.release {
  padding: 0.75rem 0;
}

.release:not(:last-child) {
  border-bottom: 1px solid var(--c-dark-gray);
}

.release-info {
  display: flex;
  flex-wrap: wrap;
}

.release-info th {
  color: var(--c-light-black);
  padding-right: 0.5rem;
}

.release-info td {
  padding-right: 1rem;
}

.release-actions {
  padding: 0.75rem 0;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: 1rem;
  align-items: center;
  font-size: var(--fs-metadata);
  font-weight: var(--fw-bold);
  text-transform: uppercase;
}

.release-actions a, button {
  color: var(--c-primary-blue);
}
