
@import '../../app/views/core/base.less';

.box {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.spinner {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;

  svg {
    width: 50px;
    height: 50px;
  }
}
