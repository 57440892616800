

.container {
  display: flex;
  align-items: center;
}

.indicator-button {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
}

.indicator-button > * {
  margin: 0;
}

.card-icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--c-light-black);
  stroke: none;
}

.badge {
  composes: badge from global;
  margin-left: 0;
}

.popout {
  overflow: visible;
}
