
.context-menu {
  margin-left: auto;
}

.cover {
  width: var(--rem-details-page-cover-width);
  height: var(--rem-details-page-cover-height);
  border-radius: .25rem .625rem .625rem .25rem;
  border: 1px solid rgba(var(--c-primary-blue-rgb), .1);
  box-shadow: 2px 3px 2px 1px rgba(var(--c-primary-blue-rgb), .15);
}

.copy-link {
  width: 100%;
}

.section-head {
  font-size: var(--fs-body);
  font-weight: var(--fw-medium-bold);
  margin-bottom: 0.75rem;
}

.recommended {
  padding-top: 2rem;
  border-top: 1px solid var(--c-dark-gray);
  --grid-layout-gap: 1.5rem;
  --grid-column-count: 3;
  --grid-item-min-width: var(--px-card-min-width);
}

.interact-container {
  display: grid;
  width: calc(var(--rem-details-page-cover-width) + 2px);
  margin: 0 auto;
  grid-template-areas: 'copy subscribe';
  grid-template-columns: auto auto;
  justify-content: space-between;
}

.interact-button {
  display: grid;
  width: fit-content;
  grid-template-areas: 'icon text';
  grid-template-columns: auto auto;
  align-items: center;
  gap: 0.25rem;
}

.interact-button div {
  grid-area: icon;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  border: 1px solid rgba(41, 98, 255, 0.1);
  border-radius: 50%;
  background-color: #F4F7FF;
}

.interact-button svg {
  height: 1.5rem;
  width: 1.25rem;
  fill: var(--c-primary-blue);
}

.interact-button span {
  grid-area: text;
  color: var(--c-primary-blue);
  text-decoration: underline;
}

.copy-link {
  grid-area: copy;
}

.subscribe {
  grid-area: subscribe;
}

.bell {
  stroke: var(--c-primary-blue);
}

.header-label {
  display: flex;
  align-items: center;
  color: var(--c-light-black);
  font-weight: var(--fw-medium);
  padding-bottom: 0.5rem;
}

.header-icon {
  width: 1.25rem;
  height: 1.25rem;
  fill: currentColor;
  margin-right: 0.5rem;
}

/* TABLET LAYOUT */

@media screen and (max-width: 1024px /* px-vp-very-wide */) {
  .cover,
  .action {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .action {
    margin-bottom: .25rem;
  }
}

/* MOBILE LAYOUT */

@media screen and (max-width: 499px /* px-vp-very-narrow */) {
  .recommended {
    grid-row-gap: 1.5rem;
    --grid-item-min-width: 1fr;
  }
}
