
.section-head {
  font-size: var(--fs-body);
  font-weight: var(--fw-medium-bold);
  margin-bottom: 0.75rem;
}

.volume-list {
  composes: list-grid from global;
  --grid-layout-gap: 1.5rem;
  --grid-column-count: 3;
  --grid-item-min-width: var(--px-card-min-width);
}

.volume-filter {
  margin-bottom: 2rem;
  width: 15rem;
}

.interact-container {
  display: grid;
  width: var(--rem-details-page-cover-width);
  margin: 0 auto;
  grid-template-areas: 'copy subscribe';
  grid-template-columns: auto auto;
  justify-content: space-between;
}

.interact-button {
  display: grid;
  width: fit-content;
  grid-template-areas: 'icon text';
  grid-template-columns: auto auto;
  align-items: center;
  gap: 0.25rem;
}

.interact-button div {
  grid-area: icon;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  border: 1px solid rgba(41, 98, 255, 0.1);
  border-radius: 50%;
  background-color: #F4F7FF;
}

.interact-button svg {
  height: 1.5rem;
  width: 1.25rem;
  fill: var(--c-primary-blue);
}

.interact-button span {
  grid-area: text;
  color: var(--c-primary-blue);
  text-decoration: underline;
}

.bell {
  stroke: var(--c-primary-blue);
}

.copy-link {
  grid-area: copy;
}

.subscribe {
  grid-area: subscribe;
}

@media screen and (max-width: 1024px /*px-vp-very-wide*/) {
  .cover {
    max-width: var(--rem-details-page-cover-width);
    margin: 0 auto;
  }
}

@media screen and (max-width: 499px /* px-vp-narrow */) {
  .volume-list {
    --grid-layout-gap: 0;
    grid-row-gap: 1.5rem;
  }
}
