
.container h3 {
  font-weight: var(--fw-bold);
  margin: 1rem 0;
}

.publisher-link {
  margin-left: 1.5rem;
}

.format-info {
  margin-left: 0.5rem;
  text-align: left;
  border-spacing: 1rem 0.5rem;
  border-collapse: initial;
}
