
.scroller {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  border-radius: var(--form-border-radius);
  z-index: 501;
  background: var(--c-light-gray);
  padding: 0.5rem 1rem;
  line-height: 0;
  box-shadow: 0px 0px 6px var(--c-shadow);
  transition: opacity 300ms;

  &.fade {
    opacity: 0.75;
  }

  &:hover, &:focus {
    opacity: 1;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--c-light-black);
  }
}
