
  @import '../../app/views/core/base.less';

  .shield {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 200;
  }

  .content {
    --c-background: @c-popout-bg-color;
    --c-text: @c-white;
    background-color: var(--c-white);
    border-radius: .5rem;
    box-shadow:
      0 16px 24px 2px rgba(0, 0, 0, 0.14),
      0 6px 30px 5px rgba(0, 0, 0, 0.12),
      0 8px 10px -5px rgba(0, 0, 0, 0.4);
    color: var(--c-dark-black);
    margin: auto;
    pointer-events: all;
    z-index: 200;
    position: relative;
  }

  .content.light {
    --c-background: @c-white;
    --c-text: @c-dark-black;
  }

  :global .popout-dismiss {
    width: 1.25rem;
    height: 1.25rem;
    padding: 0.25rem;
    box-sizing: border-box;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    line-height: 0;
    border-radius: 0.25rem;

    fill: currentColor;
    stroke: currentColor;
  }
