
@import '../../app/views/core/base.less';

.header {
  font-weight: @fw-bold;
  font-size: @fs-large-head;
  font-family: @ff-head;

  margin-bottom: 2rem;
}

.list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.5rem;
  border-bottom: 1px solid @c-darkest-gray;
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;

  @media screen and (max-width: @px-vp-max) {
    grid-template-columns: auto;
  }
}

.title {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid @c-dark-gray;

  &:last-child {
    border: 0;
    padding-bottom: 0;
  }

  @media screen and (min-width: @px-vp-max) {
    &:nth-last-child(2):nth-child(odd) {
      border: 0;
      padding-bottom: 0;
    }
  }
}

.link {
  display: block;
  position: relative;

  &:focus {
    outline: none;

    &::after {
      content: '';
      .standard-outline;
      position: absolute;
      width: calc(100% + 0.2rem);
      height: calc(100% + 0.2rem);
      top: -0.1rem;
      left: -0.1rem;
      pointer-events: none;
    }
  }
}

.view-all {
  display: inline-block;
  color: @c-primary-blue;
  .pop-strict(@c-primary-blue-light);
  .focus-outline;
}

.empty-state {
  text-align: left;
}
