
  .card {
    background-color: var(--c-white);
    border: 1px solid var(--c-card-border);
    border-radius: var(--form-border-radius);
    box-shadow: 0px 1px 6px 0px var(--c-shadow);
    position: relative;
  }

  .card.expanded {
    background-color: var(--c-lightest-gray);
  }

  .card .text {
    white-space: pre-wrap;
    word-break: break-all;
    word-break: break-word;
    overflow-wrap: break-word;
  }

  .card:not(.expanded) .text {
    display: -webkit-box;
    -webkit-line-clamp: var(--line-clamp, 1);
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .card .text.clamped {
    --line-clamp: 1;
  }

  .card .text.clamped-long {
    --line-clamp: 3;
  }

  .full-card-button {
    position: absolute;
    width: 100%;
    height: 100%;

    scroll-margin: 1rem;
  }

  .content {
    display: grid;
    grid-template-areas:
      "color-strip quote"
      "empty table"
      "empty extra";
    grid-template-columns: auto 1fr;
    grid-template-rows: repeat(3, auto);
    gap: 1rem;
    padding: 1rem;
  }

  .color-strip {
    grid-area: color-strip;

    background-color: var(--highlight-color);
    border-radius: var(--form-border-radius);
    width: 4px;
  }

  .quote {
    grid-area: quote;
  }

  .table {
    grid-area: table;

    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.25rem 1rem;

    color: var(--c-light-black);
    font-size: var(--fs-metadata);
  }

  .table dt {
    text-transform: uppercase;
  }

  .extra {
    grid-area: extra;

    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  .last-updated {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-end;

    color: var(--c-light-black);
    font-size: var(--fs-metadata);
  }

  .last-updated svg {
    width: 1rem;
    height: 1rem;
    stroke: var(--c-light-black);
  }


