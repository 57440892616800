
.card {
  padding: 1rem;
  border: 1px solid var(--c-dark-gray);
  border-radius: var(--form-border-radius);
  color: var(--c-dark-black);
}

.unreviewed {
  background-color: var(--c-light-gray);
  border-color: var(--c-darkest-gray);
}

.error header {
  font-weight: var(--fw-bold);
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--c-dark-gray);
}

.error .additional {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.5rem;
  align-items: center;
  padding-top: 1rem;
  color: var(--c-error-orange);
}

.error-icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--c-error-orange);
}

.progress header {
  display: grid;
  grid-template-columns: 1fr auto;
  font-weight: var(--fw-bold);
  padding-bottom: 1rem;
}

.progress-icon {
  height: 2rem;
  width: 2rem;
}

.progress .additional {
  color: var(--c-light-black);
  font-size: var(--fs-metadata);
}

.unreviewed-tag {
  text-transform: uppercase;
  background-color: var(--c-dark-black);
  color: var(--c-dark-gray);
  padding: 2px 5px;
  width: max-content;
  margin-bottom: 1rem;
  border-radius: var(--form-border-radius);
  font-size: var(--fs-small);
  font-weight: var(--fw-medium);
}

.finished header {
  grid-template-columns: 1fr auto;
  font-weight: var(--fw-bold);
}

.finished .additional {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(2, auto);
  grid-gap: 0.25rem 1rem;
  font-size: var(--fs-metadata);
  padding: 1rem 0;
  border-bottom: 1px solid var(--c-dark-gray);
}

.finished .additional dt {
  color: var(--c-light-black);
  text-transform: uppercase;
}

.actions {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  column-gap: 1rem;
  padding-top: 1rem;
}

.actions .clear {
  background-color: var(--c-white);
  color: var(--c-dark-black);
  border: 1px solid var(--c-light-black);
}
