
  @import '../../app/views/core/base.less';

  .container {
    .welcome-panel;
  }

  .header {
    text-align: center;
    font-size: @fs-medium-head;
    font-weight: @fw-bold;
    color: @c-dark-black;
    margin-bottom: 2rem;
  }

  .message {
    color: @c-light-black;
    margin-bottom: 2rem;
    text-align: center;
  }

  .action {
    display: block;
    width: 100%;
    background: @c-primary-red;
    border-radius: @px-border-radius;
    border: none;
    color: @c-white;
    font-size: @fs-med;
    font-weight: @fw-bold;
    padding: 1rem;
    text-shadow: none;
    text-transform: uppercase;
  }
