
.review-section {
  border: 1px solid var(--c-card-border);
  border-radius: var(--form-border-radius);
  position: relative;
}

.review-section-header {
  align-items: center;
  border-block-end: 1px solid var(--c-card-border);
  box-sizing: border-box;
  column-gap: 1rem;
  display: grid;
  grid-template-areas:
    'icon message'
    'icon description';
  grid-template-columns: auto 1fr;
  justify-items: left;
  padding: 0.5rem;
  width: 100%;
  scroll-margin: 1rem;
}

.review-section-header.expandable {
  grid-template-areas:
    'icon message chevron'
    'icon description chevron';
  grid-template-columns: auto 1fr auto;
}

.review-section-header .message {
  font-weight: var(--fw-bold);
  grid-area: message;
}

.review-section-header .description {
  grid-area: description;

  text-align: left;
}

.review-section-header .chevron {
  grid-area: chevron;
  height: 2rem;
  /* Match Collapsible transition time */
  transition: transform 200ms ease;
  width: 2rem;
}

.review-section-panel {
  background: none;
}

.review-section-panel-content {
  padding: 0.5rem;
}

.review-section.expanded .review-section-header .chevron {
  transform: rotate(-180deg);
}

.review-section.expanded .review-section-panel {
  /* Leave room for the focus outline */
  margin-block-start: 5px;
}

.card-list {
  appearance: none;

  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.icon {
  grid-area: icon;

  width: 2rem;
  height: 2rem;
}
