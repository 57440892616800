
@import '../../app/views/core/base.less';

.container {
  padding: 0.625rem 1.25rem;
  background-color: @c-bg;
  display: grid;
  grid-template-rows: auto auto 1fr auto;
}

.link {
  display: flex;
  align-items: center;

  padding: 0.625rem 1.25rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;

  color: @c-light-black;

  .focus-outline;

  :global(.badge) {
    color: inherit;
    font-weight: inherit;
  }

  @shadow-hover: rgba( 34, 34, 34, 0.10) 0px 1px 6px 0px,
      rgba( 34, 34, 34, 0.05) 5px 5px 25px 0px,
      rgba( 0,0,0, 0.05) 1px 1px 5px 0px;

  &:hover, &:focus, &.active {
    background-color: @c-white;
    box-shadow: @shadow-hover;
  }

  &:focus {
    box-shadow: @shadow-hover, @shadow-focus;
  }

  &.active {
    color: @c-active;
    font-weight: bold;

    :global(.badge) {
      background-color: lighten(@c-primary-red, 40%);
    }
  }
}

.icon {
  fill: currentColor;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1.25rem;
}

.logo-link {
  display: block;
  border-radius: 0.25rem;
  margin-bottom: 1rem;

  .focus-outline;
}

.logo {
  margin: 1.75rem auto;
  font-weight: bold;
}

.survey {
  position: sticky;
  bottom: 0;
  margin-bottom: -0.625rem;
}

.up-enter-from,
.up-leave-to {
  transform: translateY(100%);
}
