
.selected {
  background-color: var(--c-white);
  border-radius: var(--form-border-radius);
  border: 1px solid var(--c-dark-gray);
  box-shadow: 0 1px 6px 0 var(--c-shadow);
  padding: 0.25rem 0.5rem;
  font-size: var(--fs-metadata);
  margin: 0.25rem 0.5rem 0.25rem 0;
  composes: focus-outline from global;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
}

.dismiss {
  height: 0.6rem;
  width: 0.6rem;
  padding-left: 0.5rem;
  fill: var(--c-darkest-gray);
}

.indicator {
  composes: color-indicator from global;
  background-color: var(--highlight-color);
}
