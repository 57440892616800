
@import '../../app/views/core/base.less';

.container {
  section {
    padding: 1rem 0;

    &:not(:last-child) {
      border-bottom: 1px solid @c-medium-black;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.header {
  font-size: @fs-small-head;
  font-weight: @fw-bold;
  margin-bottom: 1rem;
}

.section {
  display: grid;
  grid-template-columns: 1fr auto;

  h3 {
    font-weight: bold;
    margin-bottom: 0.75rem;
  }

  p {
    color: var(--c-darkish-black);
  }
}
