
.annotation-link {
  display: flex;
  align-items: center;
  color: var(--c-light-black);
  font-weight: var(--fw-medium);
}

.annotation-link svg {
  width: 1.25rem;
  height: 1.25rem;
  fill: currentColor;
  margin-right: .5rem;
}

.annotation-link a {
  color: var(--c-light-black);
  line-height: normal;
}

/* ## TABLET LAYOUT */

@media screen and (max-width: 1024px /* px-vp-very-wide */) {
  .annotation-link {
    justify-content: center;
  }
}
