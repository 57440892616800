/* Global Vue Transitions */


/* Fade in/out */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}


/* Slide in/out */

.slide-in-right-enter-from,
.slide-in-right-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.slide-in-right-enter-active {
  transition: transform 300ms ease;
}

/*
* Set leaving content height to zero
* to prevent issues with incoming content
*/
.slide-in-right-leave-active {
  transition: transform 100ms ease;
  height: 0;
}

.slide-in-left-enter-from,
.slide-in-left-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-in-left-enter-active {
  transition: transform 300ms ease;
}

/*
* Set leaving content height to zero
* to prevent issues with incoming content
*/
.slide-in-left-leave-active {
  transition: transform 100ms ease;
  height: 0;
  opacity: 0;
}
