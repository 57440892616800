

.indicator-button {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
}

.icon {
  vertical-align: middle;
  height: 1.5rem;
  width: 1.5rem;
  stroke: var(--c-secondary-gray);
}

.label {
  color: var(--c-secondary-gray);
}

.label:first-letter {
  text-transform: uppercase;
}

.icon-only {
  display: none;
}

.description {
  grid-area: desc;
}

.actions {
  display: flex;
  margin-top: 0.5rem;
  grid-area: actions;
}

.actions > button {
  text-transform: uppercase;
  margin-right: 1rem;
  text-decoration: underline;
  text-underline-offset: 0.25rem;
  padding: 0.25rem 0;
}
