

.dialog {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  inset-inline-start: unset;
  border: 0;
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px var(--c-darkest-gray);
}

.dialog::backdrop {
  background-color: rgba(0.2, 0.2, 0.2, 0.2);
}

.content{
  padding: 1rem;
  max-width: 18rem;
}

.header-container {
  align-items: center;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr auto;
  margin-bottom: 0.5rem;
}

.header {
  align-items: center;
  display: flex;
}

.menu-heading {
  display: inline-block;
  font-size: var(--fs-body);
  font-weight: var(--fw-medium);
}

.dismiss {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
}

.dismiss svg {
  margin-right: 0;
  fill: var(--c-black);
  height: .75rem;
  width: .75rem;
}

/* Animation classes */

.dialog.animation-open {
  animation: dialog-open var(--animation-duration) ease forwards;
}

.dialog {
  animation: dialog-close var(--animation-duration) ease forwards;
}

.dialog.animation-open::backdrop {
  animation: backdrop-fade-in var(--animation-duration) ease forwards;
}

.dialog::backdrop {
  animation: backdrop-fade-out var(--animation-duration) ease forwards;
}

/* Animation keyframes */

@keyframes dialog-open {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes dialog-close {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes backdrop-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes backdrop-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
