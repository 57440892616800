
.dialog {
  background-color: var(--c-white);
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: 0;
  max-height: 70vh;
  border-radius: var(--form-border-radius);
  box-shadow: 0 2px 8px var(--c-darkest-gray);
  width: 26rem;

  /* These menus are forced to the top right, just below the header bar */
  position: fixed;
  top: 3.5rem;
  right: 1rem;
  inset-inline-start: unset;
}

.header {
  background-color: var(--c-white);
  position: sticky;
  top: 0;
  display: grid;
  grid-template-areas:
    "back heading dismiss"
    "extra extra extra";
  grid-template-columns: 1.5rem 1fr 1.5rem;
  align-items: center;
  padding: 1rem;
  z-index: 1;
}

.back-container {
  grid-area: back;
}

.heading-container {
  grid-area: heading;
  justify-self: center;
}

.dismiss-container {
  grid-area: dismiss;
}

.extra-container {
  grid-area: extra;
}

.heading {
  display: flex;
  align-items: center;
  font-weight: var(--fw-medium-bold);
}

.heading svg {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
}

.back {
  line-height: 0;
  padding: 0;
}

.back svg {
  height: 1.5rem;
  width: 1.5rem;
}

.dismiss {
  line-height: 0;
  padding: 0.25rem;
}

.dismiss svg {
  height: 1rem;
  width: 1rem;
}

.back svg,
.heading svg,
.dismiss svg {
  fill: var(--c-light-black);
}

.content {
  padding: 0 1rem 1rem 1rem;
}

@media screen and (max-width: 1200px /* px-vp-hide-nav */) {
  .dialog {
    top: 0;
    left: 0;
    border-radius: 0 0 0.5rem 0.5rem;
    width: 100vw;
    max-width: unset; /* By default dialog has a max-width setting */
  }
}

/* Animation properties */

.dialog .container {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows var(--animation-duration);
}

.dialog .container .wrapper {
  min-height: 0;
}

.dialog.animation-open .container {
  grid-template-rows: 1fr;
}

.dialog.animation-open::backdrop {
  animation: backdrop-fade-in var(--animation-duration) ease forwards;
}

.dialog::backdrop {
  animation: backdrop-fade-out var(--animation-duration) ease forwards;
}

/* Animation keyframes */

@keyframes backdrop-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes backdrop-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
