
@import '../../app/views/core/base.less';

.footer {
  background-color: var(--c-darkest-blue);
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -1rem;
}

.overdrive {
  margin-right: 1rem;
}

.od-logo {
  display: block;
  fill: @c-white;
  height: 1.75rem;
  margin-bottom: 1.5rem;
}

.copyright {
  color: @c-white;
}

.legal {
  align-self: flex-end;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 1rem;

  a {
    .pop-text(@c-white);
    color: @c-white;

    &:focus {
      .focus-outline-dark;
    }
  }

  a:not(:last-of-type) {
    margin-right: 1rem;
  }
}
