
.card-list {
  --grid-layout-gap: 1.5rem;
  --grid-column-count: 3;
  --grid-item-min-width: var(--px-card-min-width);
}

.pagination {
  margin-top: 2.5rem;
}

.loading {
  display: block;
  width: 2rem;
  height: 2rem;
  margin: 5rem auto;
}

@media screen and (max-width: 499px /* px-vp-narrow */) {
  .card-list {
    --grid-item-min-width: 1fr;
  }
}
